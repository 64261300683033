<template>
  <div class="pageBox">
    <div class="pageInner">
      <div class="pageInnerBg">
        <div class="pageTitBox">
          <div class="pageTit">成员管理</div>
          <el-button type="primary" @click="openAddBusDepartmentInfo">新增成员</el-button>
        </div>
        <div class="pageContent">
          <el-table :data="spDepartmentInfo.rows" stripe style="width: 100%">
            <el-table-column prop="name" label="姓名" :show-overflow-tooltip="true"> </el-table-column>
            <el-table-column prop="phone" label="手机" :show-overflow-tooltip="true"> </el-table-column>
            <el-table-column prop="serviceRecord" label="服务记录" :show-overflow-tooltip="true"> </el-table-column>
            <el-table-column prop="createTime" label="创建时间"> </el-table-column>
            <el-table-column fixed="right" label="操作" width="120">
              <template slot-scope="scope">
                <el-button @click.native.prevent="openEditBusDepartmentInfo(scope.row)" type="text" size="small">
                  编辑
                </el-button>
                <el-button @click.native.prevent="deleteRow(scope.row)" type="text" size="small"> 删除 </el-button>
              </template>
            </el-table-column>
          </el-table>
        </div>
        <div class="page">
          <el-pagination
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
            :page-sizes="[5, 10, 20]"
            layout="total, sizes, prev, pager, next, jumper"
            :total="spDepartmentInfo.total"
          >
          </el-pagination>
        </div>
      </div>
    </div>
    <!-- 新增 -->
    <addBusDepartmentInfo
      :addBusDepartmentInfo="addBusDepartmentShow"
      @closeAddBusDepartment="showAddBusDepartment"
      :addBusDepartment="addBusDepartment"
      :addBusDepartmentFormData="addBusDepartmentFormData"
      :editBusDepartmentFormData="editBusDepartmentFormData"
      :departmentTit="departmentTit"
    ></addBusDepartmentInfo>
  </div>
</template>
<script>
// 从session中获取token信息
import session from '@/utils/session.js'
import {
  busDepartmentByBusIdPageFindAll,
  addBusDepartment,
  putBusDepartment,
  deleteBusDepartment
} from '@/api/spBusInfo.js'
import AddBusDepartmentInfo from '@/components/shop/addBusDepartmentInfo'
export default {
  components: {
    AddBusDepartmentInfo: AddBusDepartmentInfo
  },
  data() {
    return {
      addBusDepartmentShow: false,
      editBaseInfoShow: false,
      spDepartmentInfo: [],
      baseInfo: {},
      departmentTit: '',
      addBusDepartment: {},
      serviceInfo: {},
      accountInfo: {},
      query: {
        page: 1,
        pageSize: 10
      }
    }
  },
  created() {},
  mounted() {
    this.getByIdBusDepartment()
  },
  methods: {
    async getByIdBusDepartment() {
      let spBusInfo = session.getUserInfo()
      let res = await busDepartmentByBusIdPageFindAll(this.query, spBusInfo.id)
      if (res.success) {
        this.spDepartmentInfo = res.data
      }
    },
    //分页-size
    handleSizeChange(val) {
      this.query.pageSize = val
      this.init()
    },
    //分页-page
    handleCurrentChange(val) {
      this.query.page = val
      this.init()
    },
    //新增
    openAddBusDepartmentInfo() {
      this.departmentTit = '新增成员'
      this.addBusDepartmentShow = true
    },
    showAddBusDepartment() {
      this.addBusDepartmentShow = false
    },
    // 保存 新增成员信息
    async addBusDepartmentFormData(data) {
      let res = await addBusDepartment(data)
      if (res.success) {
        this.$message({
          message: '编辑成功',
          type: 'success'
        })
        this.getByIdBusDepartment()
      }
    },
    //编辑成员信息
    openEditBusDepartmentInfo(row) {
      this.addBusDepartment = row
      this.addBusDepartmentShow = true
      this.departmentTit = '编辑成员'
    },
    // 保存 编辑成员信息
    async editBusDepartmentFormData(data) {
      let res = await putBusDepartment(data)
      if (res.success) {
        this.$message({
          message: '编辑成功',
          type: 'success'
        })
        this.getByIdBusDepartment()
      }
    },
    // 删除成员
    deleteRow(row) {
      this.$confirm('此操作将永久删除该成员, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'error'
      })
        .then(async () => {
          let res = await deleteBusDepartment(row.id)
          if (res.success) {
            this.$message({
              type: 'success',
              message: '删除成功!'
            })
            this.getByIdBusDepartment()
          } else {
            this.$message({
              type: 'error',
              message: '删除失败'
            })
          }
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: '已取消删除'
          })
        })
    }
  }
}
</script>
<style lang="less" scoped>
.pageBox {
  width: 100%;
  height: calc(100vh - 60px);
  color: #202626;
  font-size: 14px;
  background: #f2f3f4;
  scrollbar-width: none;
  -ms-overflow-style: none;
  overflow-x: hidden;
  overflow-y: auto;
}
.pageInner {
  padding: 8px;
}
.pageInnerBg {
  padding: 16px;
  background: #fff;
}
.pageTitBox {
  display: flex;
  justify-content: space-between;
  align-items: center;
  .pageTit {
    font-size: 18px;
    font-weight: bold;
  }
}
.pageContent {
  padding-top: 24px;
}
.page {
  height: 60px;
  display: flex;
  justify-content: end;
  align-items: center;
}
</style>
