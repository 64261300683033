import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import create from './utils/create'

import './plugins/element.js'
import './assets/css/public.css'
import './assets/css/theme/index.css'
import {formatDate,fmtTime,mobileStr,userNameStr,userCardNo} from '@/utils/index.js'

Vue.config.productionTip = false
Vue.prototype.formatDate =formatDate
Vue.prototype.fmtTime = fmtTime
Vue.prototype.$create = create
Vue.prototype.fmtTime = fmtTime 
Vue.prototype.mobileStr = mobileStr // 手机号中间4位加*
Vue.prototype.userNameStr = userNameStr // 姓名加密
Vue.prototype.userCardNo = userCardNo // 身份证号加密


new Vue({
  el: '#app',
  router,
  store,
  render: h => h(App)
}).$mount('#app')
