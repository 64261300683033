<template>
  <el-container>
    <el-dialog :title="departmentTit" :visible.sync="addBusDepartmentInfo" width="60%" @close="close" append-to-body>
      <el-form label-width="80px" :model="formData">
        <el-form-item label="成员名称">
          <el-input v-model="formData.name"></el-input>
        </el-form-item>
        <el-form-item label="手机号">
          <el-input v-model="formData.phone"></el-input>
        </el-form-item>
        <el-form-item label="登录密码">
          <el-input v-model="formData.password" type="password"></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="closeAddBusDepartment">取 消</el-button>
        <el-button type="primary" @click="openSave()">保存</el-button>
      </span>
    </el-dialog>
  </el-container>
</template>
<script>
// 从session中获取token信息
import session from '@/utils/session.js'
export default {
  props: [
    'addBusDepartmentInfo',
    'addBusDepartment',
    'addBusDepartmentFormData',
    'departmentTit',
    'editBusDepartmentFormData'
  ],
  data() {
    return {
      departmentTit: '',
      formData: {
        id: '',
        spBusId: '',
        name: '',
        phone: '',
        password: ''
      }
    }
  },
  watch: {
    departmentTit() {
      if (this.departmentTit == '新增成员') {
        this.formData.spBusId = ''
        this.formData.name = ''
        this.formData.phone = ''
        this.formData.password = ''
      }
    },
    addBusDepartment() {
      this.formData = this.addBusDepartment
    }
  },
  mounted() {},
  methods: {
    // 关闭弹框
    closeAddBusDepartment() {
      this.addBusDepartmentInfo = false
    },
    close() {
      this.$emit('closeAddBusDepartment')
    },
    openSave() {
      if (this.departmentTit == '新增成员') {
        let spBusInfo = session.getUserInfo()
        this.formData.spBusId = spBusInfo.id
        this.addBusDepartmentFormData(this.formData)
        this.formData.spBusId = ''
        this.formData.name = ''
        this.formData.phone = ''
        this.formData.password = ''
      } else if (this.departmentTit == '编辑成员') {
        this.editBusDepartmentFormData(this.formData)
      }
      this.addBusDepartmentInfo = false
    }
  }
}
</script>
<style lang="less" scoped>
.el-select {
  width: 100%;
}
.input_item {
  padding: 8px 0;
  .item_vlaue {
    padding-bottom: 8px;
  }
  .select {
    width: 100%;
  }
}
.el-cascader {
  width: 100%;
}
.depositBox {
  margin-top: 8px;
  .deposit_content {
    padding: 16px;
    background: #09bebe;
    border-radius: 4px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: #fff;
    .d_l_tit {
      font-size: 18px;
      font-weight: bold;
    }
    .d_l_dec {
      font-size: 14px;
    }
  }
  p {
    font-size: 12px;
  }
}
</style>
