<template>
  <div class="pageBox">
    <div class="pageInner">
      <div class="baseInfoBox">
        <el-row style="width: 100%">
          <el-col :span="12" class="baseInfoLeft">
            <div class="busImg">
              <img src="../../assets/img/avatar_MS.png" alt="" />
            </div>
            <div class="baseInfoTxtBox">
              <div class="bus_name">
                您好，{{ spBusInfo.spName }}
                <span class="logOut">{{ spBusInfo?.certificationStatus == 1 ? '已认证' : '未认证' }}</span>
              </div>
              <div class="bus_dec">
                <div class="bus_id">
                  ID：<span>{{ spBusInfo.id }}</span>
                </div>
                <!-- <div>有效期至 2024-01-01</div> -->
              </div>
              <div>
                地址：{{ areaCityProvince.province }}
                {{ areaCityProvince.city }}
                {{ areaCityProvince.area }}
                {{ spBusInfo.spAddress }}
              </div>
            </div>
          </el-col>
        </el-row>
      </div>
      <div class="group_itemsBox">
        <div class="group_items_titBox">
          <div class="group_items_tit">店铺信息</div>
          <div class="group_items_edit">
            <el-button type="primary" size="mini" @click="openEditBaseInfo">编辑</el-button>
          </div>
          <div class="group_items_dec">更改店铺信息需重新登录</div>
        </div>
        <div class="group_items_content">
          <div>商家名称：{{ !!spBusInfo.spName ? spBusInfo.spName : '-' }}</div>
          <div>公司名称：{{ !!spBusInfo.corporationName ? spBusInfo.corporationName : '-' }}</div>
          <div>商家性质：{{ !!spBusInfo.spNature ? spBusInfo.spNature : '-' }}</div>
          <div>商家规模：{{ !!spBusInfo.busScale ? spBusInfo.busScale : '-' }}人</div>
          <div>
            营业执照：
            <el-image style="width: 100px; height: 100px; padding: 0" :src="spBusInfo.busLicense"></el-image>
          </div>
          <div>商家电话：{{ !!spBusInfo.busPhone ? spBusInfo.busPhone : '-' }}</div>
          <div>
            营业地址：{{ !!areaCityProvince.province ? areaCityProvince.province : '-' }}
            {{ areaCityProvince.city }}
            {{ areaCityProvince.area }}
            {{ spBusInfo.spAddress }}
          </div>
        </div>
      </div>
      <div class="group_itemsBox">
        <div class="group_items_titBox">
          <div class="group_items_tit">服务信息</div>
          <div class="group_items_edit">
            <el-button type="primary" size="mini" @click="openEditServiceInfo">编辑</el-button>
          </div>
        </div>
        <div class="group_items_content">
          <div>服务项目：{{ !!spBusInfo.serviceItems ? spBusInfo.serviceItems : '-' }}</div>
          <div>服务区域：{{ !!spBusInfo.serviceArea ? spBusInfo.serviceArea : '-' }}</div>
          <div>服务时间：{{ !!spBusInfo.serviceTime ? spBusInfo.serviceTime : '-' }}</div>
        </div>
      </div>
      <div class="group_itemsBox">
        <div class="group_items_titBox">
          <div class="group_items_tit">账号信息</div>
          <div class="group_items_edit">
            <el-button type="primary" size="mini" @click="openEditAccountInfo">编辑</el-button>
          </div>
        </div>
        <div class="group_items_content">
          <div>账号负责人：{{ !!spBusInfo.contactPerson ? spBusInfo.contactPerson : '-' }}</div>
          <div>负责人电话：{{ spBusInfo.contactPhone != null ? this.mobileStr(spBusInfo.contactPhone) : '-' }}</div>
          <div>账号密码：********</div>
        </div>
      </div>
      <div class="group_itemsBox">
        <div class="group_items_titBox">
          <div class="group_items_tit">财务信息</div>
          <div class="group_items_edit">
            <el-button type="primary" size="mini" @click="openEditFinanceInfo">编辑</el-button>
          </div>
        </div>
        <div class="group_items_content">
          <div>财务负责人：{{ !!spBusInfo.financeName ? spBusInfo.financeName : '-' }}</div>
          <div>财务负责人电话：{{ spBusInfo.financePhone != null ? this.mobileStr(spBusInfo.financePhone) : '-' }}</div>
          <div>财务密码：********</div>
        </div>
      </div>
    </div>
    <!-- 编辑店铺基本信息 -->
    <editBaseInfo
      :editBaseInfo="editBaseInfoShow"
      @closeEditBaseInfo="showEditBaseInfo"
      :baseInfo="baseInfo"
      :editBaseFormData="editBaseFormData"
    ></editBaseInfo>
    <!-- 编辑服务信息 -->
    <editServiceInfo
      :editServiceInfo="editServiceInfoShow"
      @closeEditServiceInfo="showEditServiceInfo"
      :serviceInfo="serviceInfo"
      :editServiceFormData="editServiceFormData"
    ></editServiceInfo>
    <!-- 编辑账号信息 -->
    <editAccountInfo
      :editAccountInfo="editAccountInfoShow"
      @closeEditAccountInfo="showEditAccountInfo"
      :accountInfo="accountInfo"
      :editAccountFormData="editAccountFormData"
    ></editAccountInfo>
    <!-- 编辑财务信息 -->
    <editFinanceInfo
      :editFinanceInfo="editFinanceInfoShow"
      @closeEditFinanceInfo="showEditFinanceInfo"
      :financeInfo="financeInfo"
      :editFinanceFormData="editFinanceFormData"
    ></editFinanceInfo>
  </div>
</template>
<script>
// 从session中获取token信息
import session from '@/utils/session.js'
import {
  getByIdBusInfo,
  shopBaseInfoPut,
  shopServiceInfoPut,
  shopAccountInfoPut,
  shopFinanceInfoPut
} from '@/api/spBusInfo.js'
import { getAreaCityProvince } from '@/api/public.js'
import EditBaseInfo from '@/components/shop/editBaseInfo'
import EditServiceInfo from '@/components/shop/editServiceInfo'
import EditAccountInfo from '@/components/shop/editAccountInfo'
import EditFinanceInfo from '@/components/shop/editFinanceInfo'
export default {
  components: {
    EditBaseInfo: EditBaseInfo,
    EditServiceInfo: EditServiceInfo,
    EditAccountInfo: EditAccountInfo,
    EditFinanceInfo: EditFinanceInfo
  },
  data() {
    return {
      editBaseInfoShow: false,
      editServiceInfoShow: false,
      editAccountInfoShow: false,
      editFinanceInfoShow: false,
      spBusInfo: {},
      baseInfo: {},
      areaCityProvince: {},
      serviceInfo: {},
      accountInfo: {},
      query: {
        page: 1,
        pageSize: 10
      },
      financeInfo: {}
    }
  },
  created() {},
  mounted() {
    this.getByIdBusInfo()
  },
  methods: {
    async getByIdBusInfo() {
      let spBusInfo = session.getUserInfo()
      let res = await getByIdBusInfo(spBusInfo.id)
      if (res.success) {
        this.spBusInfo = res.data
        let ress = await getAreaCityProvince(this.spBusInfo.spAreaId)
        if (ress.success) {
          this.areaCityProvince = ress.data
        }
      }
    },
    //编辑店铺基本信息
    openEditBaseInfo() {
      let formData = {
        spName: this.spBusInfo.spName,
        corporationName: this.spBusInfo.corporationName,
        spNature: this.spBusInfo.spNature,
        busScale: this.spBusInfo.busScale,
        busLicense: this.spBusInfo.busLicense,
        busPhone: this.spBusInfo.busPhone,
        spProvinceId: this.spBusInfo.spProvinceId,
        spCityId: this.spBusInfo.spCityId,
        spAreaId: this.spBusInfo.spAreaId,
        spAddress: this.spBusInfo.spAddress
      }
      this.baseInfo = formData
      this.editBaseInfoShow = true
    },
    showEditBaseInfo() {
      this.editBaseInfoShow = false
    },
    // 保存 编辑店铺基本信息
    async editBaseFormData(data) {
      let busId = this.spBusInfo.id
      let res = await shopBaseInfoPut(data, busId)
      if (res.success) {
        this.$message({
          message: '编辑成功',
          type: 'success'
        })
        this.getByIdBusInfo()
        this.$router.push({ path: '/login' })
      }
    },
    //编辑服务信息
    openEditServiceInfo() {
      let formData = {
        serviceItems: this.spBusInfo.serviceItems,
        serviceArea: this.spBusInfo.serviceArea,
        serviceTime: this.spBusInfo.serviceTime
      }
      this.serviceInfo = formData
      this.editServiceInfoShow = true
    },
    showEditServiceInfo() {
      this.editServiceInfoShow = false
    },
    // 保存 编辑服务信息
    async editServiceFormData(data) {
      let busId = this.spBusInfo.id
      let res = await shopServiceInfoPut(data, busId)
      if (res.success) {
        this.$message({
          message: '编辑成功',
          type: 'success'
        })
        this.getByIdBusInfo()
      }
    },
    //编辑账号信息
    openEditAccountInfo() {
      let formData = {
        contactPerson: this.spBusInfo.contactPerson,
        contactPhone: this.spBusInfo.contactPhone,
        accountPassword: this.spBusInfo.accountPassword
      }
      this.accountInfo = formData
      this.editAccountInfoShow = true
    },
    showEditAccountInfo() {
      this.editAccountInfoShow = false
    },
    // 保存 编辑服务信息
    async editAccountFormData(data) {
      let busId = this.spBusInfo.id
      let res = await shopAccountInfoPut(data, busId)
      if (res.success) {
        this.$message({
          message: '编辑成功',
          type: 'success'
        })
        this.getByIdBusInfo()
      }
    },
    //编辑财务信息
    openEditFinanceInfo() {
      let spBusInfo = this.spBusInfo
      if (spBusInfo.financePhone != null) {
        let formData = {
          financeName: this.spBusInfo.financeName,
          financePhone: this.spBusInfo.financePhone,
          financePassword: this.spBusInfo.financePassword
        }
        this.financeInfo = formData
        this.editFinanceInfoShow = true
      } else {
        this.financeInfo = null
        this.editFinanceInfoShow = true
      }
    },
    showEditFinanceInfo() {
      this.editFinanceInfoShow = false
    },
    // 保存 编辑财务信息
    async editFinanceFormData(data) {
      let busId = this.spBusInfo.id
      let res = await shopFinanceInfoPut(data, busId)
      if (res.success) {
        this.$message({
          message: '编辑成功',
          type: 'success'
        })
        this.getByIdBusInfo()
      }
    }
  }
}
</script>
<style lang="less" scoped>
.pageBox {
  width: 100%;
  height: calc(100vh - 60px);
  color: #202626;
  font-size: 14px;
  background: #f2f3f4;
  scrollbar-width: none;
  -ms-overflow-style: none;
  overflow-x: hidden;
  overflow-y: auto;
}
.pageInner {
  padding: 8px;
}
.baseInfoBox {
  padding: 16px;
  background: #fff;
  display: flex;
  justify-content: space-between;
  align-items: center;
  .baseInfoLeft {
    display: flex;
    align-items: center;
    .busImg {
      width: 60px;
      height: 60px;
    }
    .baseInfoTxtBox {
      padding-left: 24px;
      .bus_name {
        font-size: 16px;
        font-weight: bold;
      }
      .bus_dec {
        font-size: 12px;
        display: flex;
        align-items: center;
        .bus_id {
          padding: 4px 0;
          padding-right: 24px;
        }
      }
      .logOut {
        margin-left: 8px;
        font-size: 12px;
        padding: 4px 16px;
        border: #09bebe 1px solid;
        border-radius: 4px;
        color: #09bebe;
      }
    }
  }
  .baseInfoRight {
    display: flex;
    align-items: center;
    .baser_items {
      padding-left: 60px;
      .baser_itemTit {
        font-size: 14px;
        opacity: 0.7;
        padding-bottom: 4px;
      }
    }
  }
}
.group_itemsBox {
  margin-top: 8px;
  padding: 16px;
  background: #fff;
  .group_items_titBox {
    font-size: 16px;
    font-weight: bold;
    display: flex;
    align-items: center;
    padding-bottom: 16px;
    .group_items_edit {
      padding-left: 16px;
    }
    .group_items_dec {
      font-size: 14px;
      font-weight: 400;
      opacity: 0.7;
      padding-left: 16px;
    }
  }
  .group_items_content {
    div {
      padding: 8px 0;
    }
  }
}
</style>
