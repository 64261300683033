<template>
  <el-container>
    <el-dialog title="编辑店铺信息" :visible.sync="editBaseInfo" width="60%" @close="close" append-to-body>
      <el-form label-width="80px" :model="formData">
        <el-form-item label="商家名称">
          <el-input v-model="formData.spName"></el-input>
        </el-form-item>
        <el-form-item label="公司名称">
          <el-input v-model="formData.corporationName"></el-input>
        </el-form-item>
        <el-form-item label="商家性质">
          <el-select v-model="formData.spNature" placeholder="请选择">
            <el-option v-for="item in spNatureList" :key="item.value" :label="item.value" :value="item.value">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="商家规模">
          <el-select v-model="formData.busScale" placeholder="请选择">
            <el-option v-for="item in busScaleList" :key="item.value" :label="item.value" :value="item.value">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="营业执照">
          <el-input v-model="formData.busLicense"></el-input>
          <el-upload
            class="upload-demo"
            :action="`https://kwangda.com:8092/sp/busInfo/busUpload/file/${formData.corporationName}${
              new Date().getTime() / 1000
            }`"
            :headers="headerObj"
            :before-upload="beforeFileUp"
            :on-success="fileSuced"
            multiple
            :limit="1"
          >
            <el-button size="small" type="primary">点击上传</el-button>
          </el-upload>
        </el-form-item>
        <el-form-item label="商家电话">
          <el-input v-model="formData.busPhone"></el-input>
        </el-form-item>
        <el-form-item label="地址">
          <el-cascader
            ref="cascader"
            v-model="formData.spAreaId"
            :options="AdsOptions"
            :props="proCityAreaTreeProps"
            @change="handleChange"
            filterable
            style="width: 100%"
          ></el-cascader>
        </el-form-item>
        <el-form-item label="详细地址">
          <el-input v-model="formData.spAddress"></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="closeEditBaseInfo">取 消</el-button>
        <el-button type="primary" @click="openSave()">保存</el-button>
      </span>
    </el-dialog>
  </el-container>
</template>
<script>
// 自定义拖拽
import elDragDialog from '@/directive/el-dragDialog'
import { proCityAreaFindAllList } from '@/api/public.js'
export default {
  props: ['editBaseInfo', 'baseInfo', 'editBaseFormData'],
  directives: {
    elDragDialog
  },
  data() {
    return {
      headerObj() {
        Authorization: 'Bearer' + sessionStorage.getItem('token')
      },
      spNatureList: [
        { value: '私营', label: 'siying' },
        { value: '国营', label: 'guoying' }
      ],
      busScaleList: [
        { value: '0-49', label: '1' },
        { value: '50-99', label: '2' },
        { value: '100-199', label: '3' },
        { value: '200-499', label: '4' },
        { value: '500-1000', label: '5' },
        { value: '1500以上', label: '6' }
      ],
      formData: {
        spName: '',
        corporationName: '',
        spNature: '',
        busScale: '',
        busLicense: '',
        busPhone: '',
        spProvinceId: '',
        spCityId: '',
        spAreaId: '',
        spAddress: ''
      },
      AdsOptions: [],
      proCityAreaTreeProps: {
        emitPath: false,
        value: 'code',
        label: 'name',
        children: 'children'
      }
    }
  },
  watch: {
    baseInfo(n, o) {
      this.formData = n
      this.getProCityAreaFindAllList()
    }
  },
  mounted() {},
  methods: {
    async getProCityAreaFindAllList() {
      let res = await proCityAreaFindAllList()
      if (res.success) {
        this.AdsOptions = res.data
      }
    },
    handleChange(value) {
      let nodesObj = this.$refs['cascader'].getCheckedNodes()
      this.formData.spProvinceId = nodesObj[0].path[0]
      this.formData.spCityId = nodesObj[0].path[1]
      this.formData.spAreaId = nodesObj[0].path[2]
    },
    // 关闭弹框
    closeEditBaseInfo() {
      this.editBaseInfo = false
    },
    close() {
      this.$emit('closeEditBaseInfo')
    },
    openSave() {
      this.editBaseFormData(this.formData)
      this.editBaseInfo = false
    },
    beforeFileUp() {
      if (!this.formData.corporationName) {
        this.$message.error('请先输入公司名称')
        return false
      }
    },
    fileSuced(res) {
      if (res.success) {
        this.formData.busLicense = res.data
        this.$message.success('上传成功')
      }
    }
  }
}
</script>
<style lang="less" scoped>
.el-select {
  width: 100%;
}
.input_item {
  padding: 8px 0;
  .item_vlaue {
    padding-bottom: 8px;
  }
  .select {
    width: 100%;
  }
}
.el-cascader {
  width: 100%;
}
.depositBox {
  margin-top: 8px;
  .deposit_content {
    padding: 16px;
    background: #09bebe;
    border-radius: 4px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: #fff;
    .d_l_tit {
      font-size: 18px;
      font-weight: bold;
    }
    .d_l_dec {
      font-size: 14px;
    }
  }
  p {
    font-size: 12px;
  }
}
</style>
