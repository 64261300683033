import request from '@/utils/request'

// 省市区 - 三级联动
export function proCityAreaFindAllList() {
  return request({
    url: '/sys/province/proCityAreaFindAll',
    method: 'get',
  })
}
// 省市区 - 通过地区code查询上级
export function getAreaCityProvince(code) {
  return request({
    url: '/sys/area/areaCityProvince/' + code,
    method: 'get',
  })
}
// 查询所属地区顾问
export function getSysAccountList(resArea) {
  return request({
    url: '/sys/account/byResArea/' + resArea,
    method: 'get',
  })
}
// 根据DisplayTerminal查询全部知识库分类
export function getKnowClassDisplayTerminalList(displayTerminalList) {
  return request({
    url: '/sys/knowClass/displayTerminalList/' + displayTerminalList,
    method: 'get',
  })
}
// 根据分类查询列表知识库
export function getKnowPageAll(classId, data) {
  return request({
    url: '/sys/know/list/' + classId,
    method: 'get',
    params: data
  })
}