<template>
  <div class="pageBox">
    <notCertifiedVue v-if="certificationStatus == 0" />
    <div v-if="certificationStatus == 1" class="pageTitBox">
      <div class="pageTitInner">
        <div class="pageTit">订单管理</div>
        <el-tabs v-model="activeName" @tab-click="handleClick" style="margin: 0">
          <el-tab-pane label="确认预约" name="confirm"></el-tab-pane>
          <el-tab-pane label="服务进度" name="service"></el-tab-pane>
          <el-tab-pane label="订单完成" name="cancel"></el-tab-pane>
          <el-tab-pane label="退款/售后" name="complete"></el-tab-pane>
          <el-tab-pane label="取消订单" name="afterSales"></el-tab-pane>
        </el-tabs>
        <div class="pageTitRight"></div>
      </div>
      <el-row :gutter="8" class="pageList">
        <emptyPageVue v-if="dataList.length < 1" />
        <div v-if="dataList.length > 0">
          <el-col :span="14">
            <div class="grid-content bg-purple">
              <div style="font-size: 12px">共查询出：{{ total }}</div>
              <div class="pageListLeft">
                <!-- 显示退款/售后 -->
                <div v-if="refundList != null">
                  <div v-for="(v, t) in refundList" :key="t">
                    <div class="pageListLeft_items" :style="{ border: t == activeIndex ? '#09bebe 2px solid' : '' }">
                      <div class="p_items_left">
                        <div class="p_items_tit" @click="openRefundDateils(v, t)">
                          <div>
                            {{ v.serviceTitle }}
                          </div>
                          <div class="p_items_orderStatus">
                            <span class="pio_left">
                              {{ v.serviceType == 1 ? '售后' : v.serviceType == 0 ? '退款' : '' }}
                            </span>
                            <div class="pio_right">
                              <span v-if="v.refundStatus == 0">菏康审核</span>
                              <span v-if="v.refundStatus == 1">
                                <span v-if="v.hkResult == null">菏康审核</span>
                                <span v-if="v.hkResult != null">商家审核</span>
                              </span>
                              <span v-if="v.refundStatus == 2">已完成</span>
                              <span v-if="v.refundStatus == 3">已拒绝</span>
                            </div>
                          </div>
                        </div>
                        <div class="p_items_dec">
                          {{ v.serviceDec }}
                        </div>
                        <div class="p_items_tag">
                          <span><i class="el-icon-time"></i>申请时间：{{ formatDate(v.createTime) }}</span>
                        </div>
                      </div>
                      <div class="p_items_right">
                        <i class="el-icon-right" :style="{ color: t == activeIndex ? '#09bebe' : '' }"></i>
                      </div>
                    </div>
                  </div>
                </div>
                <!-- 默认显示 -->
                <div v-if="refundList == null && total > 0">
                  <div v-for="(items, index) in listCmputed()" :key="index">
                    <div
                      class="pageListLeft_items"
                      :style="{ border: index == activeIndex ? '#09bebe 2px solid' : '' }"
                    >
                      <div class="p_items_left">
                        <div class="p_items_tit" @click="openDateils(items, index)">
                          <div>
                            {{ JSON.parse(items.goodsContent)?.serviceTitle }}
                          </div>
                          <div class="p_items_orderStatus">
                            <span class="pio_left">
                              {{ JSON.parse(items.spServiceInfo)?.spServiceUser ? '已分配' : '未分配' }}
                            </span>
                            <span class="pio_right" v-if="items.ordersStatus == 2"> 待客户确认预约</span>
                            <div v-if="items.ordersStatus == 3" class="pio_right">
                              <span v-if="JSON.parse(items.spServiceInfo) != null">
                                {{
                                  JSON.parse(items.spServiceInfo)?.status == 'start'
                                    ? '服务开始'
                                    : JSON.parse(items.spServiceInfo)?.status == 'end'
                                    ? '服务结束'
                                    : JSON.parse(items.spServiceInfo)?.status == null
                                    ? '待服务'
                                    : ''
                                }}
                              </span>
                              <span v-if="JSON.parse(items.spServiceInfo) == null">
                                {{ JSON.parse(items.spServiceInfo) == null ? '待服务' : '' }}
                              </span>
                            </div>
                            <span class="pio_right" v-if="items.ordersStatus == 4"> 已完成</span>
                            <span class="pio_right" v-if="items.ordersStatus == 7"> 已取消</span>
                          </div>
                        </div>
                        <div class="p_items_dec">
                          {{ JSON.parse(items.goodsContent)?.serviceDec }}
                        </div>
                        <div class="p_items_tag">
                          <span
                            ><i class="el-icon-location"></i>{{ JSON.parse(items.addressInfo)?.userProvince
                            }}{{ JSON.parse(items.addressInfo)?.userCity
                            }}{{ JSON.parse(items.addressInfo)?.userArea }}</span
                          >
                          <span><i class="el-icon-time"></i>{{ items.createTime }}</span>
                        </div>
                      </div>
                      <div class="p_items_right">
                        <i class="el-icon-right" :style="{ color: index == activeIndex ? '#09bebe' : '' }"></i>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </el-col>
          <el-col :span="10" v-if="total > 0">
            <div class="grid-content bg-purple">
              <div class="pageListRight">
                <div class="p_mapBox">
                  <div class="p_mapInner">
                    <!-- 地图显示 -->
                    <iframe
                      src="baiduMap/index.html"
                      scrolling="no"
                      style="width: 100%; height: 320px"
                      name="addressData"
                      frameborder="0"
                    ></iframe>
                  </div>
                </div>
                <div class="p_orderBox">
                  <div>
                    <div class="p_orderTit">
                      {{ JSON.parse(orderDateils.goodsContent).serviceTitle }}
                    </div>
                    <div class="p_orderStatus">
                      <div class="p_o_tit">订单状态</div>
                      <div class="p_o_dec">
                        {{
                          orderDateils.ordersStatus == 1
                            ? '待商家接单'
                            : orderDateils.ordersStatus == 2
                            ? '待客户确认预约'
                            : orderDateils.ordersStatus == 4
                            ? '已完成'
                            : orderDateils.ordersStatus == 5
                            ? '退款/售后'
                            : orderDateils.ordersStatus == 7
                            ? '取消订单'
                            : ''
                        }}
                        <div v-if="orderDateils.ordersStatus == 3">
                          {{
                            JSON.parse(orderDateils.spServiceInfo)?.status == 'start'
                              ? '服务开始'
                              : JSON.parse(orderDateils.spServiceInfo)?.status == 'end'
                              ? '服务结束'
                              : JSON.parse(orderDateils.spServiceInfo)?.status == null
                              ? '待服务'
                              : ''
                          }}
                        </div>
                      </div>
                      <span style="font-size: 14px; margin-left: 8px" v-if="orderDateils.ordersStatus == 2"
                        >超1小时将自动确认</span
                      >
                    </div>
                  </div>
                  <div class="userInfo">
                    <div class="userInfoInner">
                      <div class="userInfoLeft">
                        <div class="userName">
                          {{ userNameStr(JSON.parse(orderDateils.addressInfo)?.name) }}
                          {{ JSON.parse(orderDateils.addressInfo)?.gender }}
                        </div>
                        <div>
                          {{ this.mobileStr(JSON.parse(orderDateils.addressInfo)?.phone) }}
                        </div>
                      </div>
                      <div class="userCallDec">建议APP联系客户</div>
                    </div>
                  </div>
                  <div class="p_userOrderBox">
                    <div>
                      <span>上门地址：</span>
                      <span>
                        {{ JSON.parse(orderDateils.addressInfo).userProvince }}
                        {{ JSON.parse(orderDateils.addressInfo).userCity }}
                        {{ JSON.parse(orderDateils.addressInfo).userArea }}
                        {{ JSON.parse(orderDateils.addressInfo).detailAdd }}
                      </span>
                    </div>
                    <div>
                      <span>上门时间：</span>
                      <span>
                        {{ JSON.parse(orderDateils.goodsContent).date }}
                        {{ JSON.parse(orderDateils.goodsContent).time }}</span
                      >
                    </div>
                    <div v-if="JSON.parse(orderDateils.goodsContent)?.orderCleanArea">
                      <span>清洁面积：</span>
                      <span> {{ JSON.parse(orderDateils.goodsContent)?.orderCleanArea }}</span>
                    </div>
                    <div>
                      <span>计费方式：</span>
                      <span> {{ JSON.parse(orderDateils.goodsContent)?.orderServiceTime }}</span>
                    </div>
                  </div>
                  <div class="p_o_info">
                    <div class="p_o_i_tit">服务内容</div>
                    <div
                      class="p_o_info_items"
                      v-for="(items, i) in JSON.parse(goodsDateils.serviceContent)?.domains"
                      :key="i"
                    >
                      <el-row>
                        <el-col :span="4" class="p_o_info_items_tit">{{ items.value }}:</el-col>
                        <el-col :span="20">
                          {{ items.label }}
                        </el-col>
                      </el-row>
                    </div>
                    <div class="p_o_info_items">
                      <el-row>
                        <el-col :span="4" class="p_o_info_items_tit">可选清洁面积:</el-col>
                        <el-col :span="20">
                          <span
                            style="padding-right: 16px"
                            v-for="(items, t) in JSON.parse(goodsDateils.serviceContent)?.cleanArea"
                            :key="t"
                          >
                            {{ items.value }}
                          </span>
                        </el-col>
                      </el-row>
                    </div>
                    <div class="p_o_info_items">
                      <el-row>
                        <el-col :span="4" class="p_o_info_items_tit">可选清洁时长:</el-col>
                        <el-col :span="20">
                          <span
                            style="padding-right: 16px"
                            v-for="(items, a) in JSON.parse(goodsDateils.serviceContent)?.serviceDuration"
                            :key="a"
                          >
                            {{ items.value }}
                          </span>
                        </el-col>
                      </el-row>
                    </div>
                    <div class="p_o_i_tit">注意事项：</div>
                    <div
                      class="p_o_info_items"
                      v-for="(items, b) in JSON.parse(goodsDateils.serviceContent)?.lookMatter"
                      :key="b"
                    >
                      <el-row>
                        <el-col :span="4" class="p_o_info_items_tit">{{ items.value }}:</el-col>
                        <el-col :span="20">
                          {{ items.label }}
                        </el-col>
                      </el-row>
                    </div>
                  </div>
                  <div class="p_order_paceBox" style="margin-bottom: 24px" v-if="refundList != null">
                    <div class="p_order_paceTit">退款/售后进度</div>
                    <div class="p_order_paceInner">
                      <div
                        class="complete p_order_pace_items"
                        v-if="refundInfo.refundPayStatus != null"
                        :style="{ opacity: refundInfo.refundStatus < 2 ? 1 : 0.5 }"
                      >
                        <div class="lin6"></div>
                        <div>
                          <i
                            class="el-icon-success co_icons"
                            :class="refundInfo.refundStatus < 2 ? 'el-icon-warning co_icons_active' : 'el-icon-success'"
                          ></i>
                        </div>
                        <div class="complete_txtBox">
                          <div class="complete_txt">
                            {{
                              refundInfo.refundPayStatus == 0
                                ? '退款中'
                                : refundInfo.refundPayStatus == 1
                                ? '退款成功'
                                : refundInfo.refundPayStatus == 2
                                ? '退款失败'
                                : ''
                            }}
                          </div>
                          <div class="complete_time">{{ formatDate(refundInfo.updateTime) }}</div>
                        </div>
                      </div>
                      <div
                        class="complete p_order_pace_items"
                        :style="{ opacity: refundInfo.spBusResult != null ? 0.5 : 1 }"
                      >
                        <div class="lin6"></div>
                        <div>
                          <i
                            class="el-icon-success co_icons"
                            :class="
                              refundInfo.spBusResult == null ? 'el-icon-warning co_icons_active' : 'el-icon-success'
                            "
                          ></i>
                        </div>
                        <div class="complete_txtBox">
                          <div class="complete_txt">商家审核</div>
                          <div class="complete_time">{{ formatDate(refundInfo.updateTime) }}</div>
                          <div class="complete_time">
                            审核结果：{{
                              refundInfo.spBusResult == 1 ? '通过' : refundInfo.spBusResult == 0 ? '不通过' : '审核中'
                            }}
                          </div>
                          <div class="complete_time" v-if="refundInfo.spBusResultNotes != null">
                            备注：{{ refundInfo.spBusResultNotes }}
                          </div>
                          <el-button
                            v-if="refundInfo.spBusResult == null"
                            size="mini"
                            type="primary"
                            @click="openConfirmOrder"
                            >审核</el-button
                          >
                        </div>
                      </div>
                      <div
                        class="complete p_order_pace_items"
                        :style="{ opacity: refundInfo.hkResult != null ? 0.5 : 1 }"
                      >
                        <div class="lin6"></div>
                        <div>
                          <i
                            class="el-icon-success co_icons"
                            :class="refundInfo.hkResult == null ? 'el-icon-warning co_icons_active' : 'el-icon-success'"
                          ></i>
                        </div>
                        <div class="complete_txtBox">
                          <div class="complete_txt">菏康审核</div>
                          <div class="complete_time">{{ formatDate(refundInfo.updateTime) }}</div>
                          <div class="complete_time">
                            审核结果：{{
                              refundInfo.hkResult == 1 ? '通过' : refundInfo.hkResult == 0 ? '不通过' : '审核中'
                            }}
                          </div>
                          <div class="complete_time" v-if="refundInfo.hkResultNotes != null">
                            备注：{{ refundInfo.hkResultNotes }}
                          </div>
                        </div>
                      </div>
                      <div class="complete p_order_pace_items">
                        <div>
                          <i class="el-icon-success co_icons"></i>
                        </div>
                        <div class="complete_txtBox">
                          <div class="complete_txt">
                            已申请
                            {{ refundInfo.serviceType == 1 ? '售后' : refundInfo.serviceType == 0 ? '退款' : '' }}
                          </div>
                          <div class="complete_time">{{ formatDate(refundInfo.createTime) }}</div>
                          <div class="complete_time">订单单号：{{ refundInfo.userOrderId }}</div>
                          <div class="complete_time">退款单号：{{ refundInfo.id }}</div>
                          <div class="complete_time">原因：{{ refundInfo.serviceCause }}</div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="p_order_paceBox">
                    <div class="p_order_paceTit">订单进度</div>
                    <div class="p_order_paceInner">
                      <!-- <div class="lin7"></div> -->
                      <div v-if="orderDateils.ordersStatus >= 4">
                        <div
                          class="complete p_order_pace_items"
                          v-if="JSON.parse(orderDateils.serviceEvaluation)?.status == true"
                        >
                          <div class="lin5" v-if="orderDateils.ordersStatus >= 4"></div>
                          <div>
                            <i class="el-icon-success co_icons"></i>
                          </div>
                          <div class="complete_txtBox">
                            <div class="complete_txt">已完成</div>
                            <div class="complete_time">{{ orderDateils.updateTime }}</div>
                          </div>
                        </div>
                      </div>
                      <div
                        class="complete p_order_pace_items"
                        :style="{
                          opacity: orderDateils.payAmount == null ? 1 : 0.5
                        }"
                        v-if="(orderDateils.repairAmount != null) & (orderDateils.ordersStatus >= 3)"
                      >
                        <div
                          class="lin4"
                          v-if="orderDateils.ordersStatus > 3 || orderDateils.repairAmount != null"
                        ></div>
                        <div>
                          <i
                            class="co_icons"
                            :class="
                              orderDateils.payAmount == null ? 'el-icon-warning co_icons_active' : 'el-icon-success'
                            "
                          ></i>
                        </div>
                        <div class="complete_txtBox">
                          <div class="complete_txt">
                            <span>{{ orderDateils.payAmount == null ? '待补差金额：' : '已补差价金额' }}</span>
                            <span style="color: #09bebe"> ¥{{ orderDateils.repairAmount }} </span>
                          </div>
                          <div class="complete_time">{{ orderDateils.updateTime }}</div>
                          <div class="complete_time">{{ orderDateils.repairAmountContent }}</div>
                        </div>
                      </div>
                      <div
                        class="complete p_order_pace_items"
                        v-if="
                          (JSON.parse(orderDateils.serviceEvaluation)?.status == true) &
                          (orderDateils.ordersStatus >= 3)
                        "
                      >
                        <div
                          class="lin4"
                          v-if="orderDateils.ordersStatus > 3 || orderDateils.repairAmount != null"
                        ></div>
                        <div>
                          <i class="el-icon-success co_icons"></i>
                        </div>
                        <div class="complete_txtBox">
                          <div class="complete_txt">已确认服务</div>
                          <div class="complete_time">
                            {{ JSON.parse(orderDateils.serviceEvaluation)?.createTime }}
                          </div>
                          <div class="complete_time">
                            <div
                              v-for="(items, index) in JSON.parse(orderDateils.serviceEvaluation)?.content"
                              :key="index"
                            >
                              <span style="padding-right: 8px">{{ items.label }}:</span>
                              <span>{{ items.value }}</span>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div v-if="orderDateils.ordersStatus >= 3">
                        <div v-if="JSON.parse(orderDateils.spServiceInfo) != null">
                          <div
                            class="complete p_order_pace_items"
                            :style="{ opacity: JSON.parse(orderDateils.serviceEvaluation)?.status == false ? 1 : 0.5 }"
                            v-if="JSON.parse(orderDateils.spServiceInfo)?.status != null"
                          >
                            <div class="lin3" v-if="orderDateils.ordersStatus > 2"></div>
                            <div>
                              <i
                                :class="
                                  JSON.parse(orderDateils.serviceEvaluation)?.status == false
                                    ? 'el-icon-warning co_icons_active'
                                    : 'el-icon-success'
                                "
                                class="el-icon-success co_icons"
                              ></i>
                            </div>
                            <div class="complete_txtBox">
                              <div class="complete_txt">
                                {{
                                  JSON.parse(orderDateils.spServiceInfo)?.status == 'start'
                                    ? '服务开始'
                                    : JSON.parse(orderDateils.spServiceInfo)?.status == 'end'
                                    ? '服务结束'
                                    : ''
                                }}
                                <span> {{ spBusInfo.spName }} </span>
                                <span v-if="JSON.parse(orderDateils.spServiceInfo)?.status == 'end'">
                                  <span v-if="JSON.parse(orderDateils.serviceEvaluation)?.status == false"
                                    >待客户确认服务</span
                                  >
                                </span>
                              </div>
                              <div class="complete_time">
                                {{
                                  JSON.parse(orderDateils.spServiceInfo)?.status == 'start'
                                    ? JSON.parse(orderDateils.spServiceInfo)?.start.startTime
                                    : JSON.parse(orderDateils.spServiceInfo)?.status == 'end'
                                    ? JSON.parse(orderDateils.spServiceInfo)?.end.endTime
                                    : orderDateils.updateTime
                                }}
                              </div>
                              <div class="complete_time">
                                服务人员：{{ JSON.parse(orderDateils.spServiceInfo)?.spServiceUser?.name }} 手机：{{
                                  JSON.parse(orderDateils.spServiceInfo)?.spServiceUser?.mobile
                                }}
                              </div>
                              <div
                                v-if="JSON.parse(orderDateils.spServiceInfo)?.status != null"
                                class="complete_details"
                                @click="openServiceDateils"
                              >
                                查看详情>>
                              </div>
                            </div>
                          </div>
                          <div v-if="JSON.parse(orderDateils.spServiceInfo)?.status == null">
                            <div class="complete p_order_pace_items" style="opacity: 1">
                              <div class="lin3" v-if="orderDateils.ordersStatus > 2"></div>
                              <div>
                                <i class="el-icon-warning co_icons co_icons_active"></i>
                              </div>
                              <div class="complete_txtBox">
                                <div class="complete_txt">
                                  待服务
                                  <span> {{ spBusInfo.spName }} </span>
                                </div>
                                <div class="complete_time">{{ orderDateils.updateTime }}</div>
                                <div class="complete_time">
                                  服务人员：{{ JSON.parse(orderDateils.spServiceInfo)?.spServiceUser?.name }} 手机：{{
                                    JSON.parse(orderDateils.spServiceInfo)?.spServiceUser?.mobile
                                  }}
                                </div>
                                <div class="complete_details" @click="openServiceDateils">查看详情>></div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div v-if="JSON.parse(orderDateils.spServiceInfo) == null">
                          <div class="complete p_order_pace_items">
                            <div class="lin3" v-if="orderDateils.ordersStatus > 2"></div>
                            <div>
                              <i class="el-icon-success co_icons co_icons_active"></i>
                            </div>
                            <div class="complete_txtBox">
                              <div class="complete_txt">
                                待服务
                                <span> {{ spBusInfo.spName }} </span>
                              </div>
                              <div class="complete_time">{{ orderDateils.updateTime }}</div>
                              <div class="complete_time">
                                服务人员：{{ JSON.parse(orderDateils.spServiceInfo)?.spServiceUser?.name }} 手机：{{
                                  JSON.parse(orderDateils.spServiceInfo)?.spServiceUser?.mobile
                                }}
                              </div>
                              <div class="complete_details" @click="openServiceDateils">查看详情>></div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div
                        class="complete p_order_pace_items"
                        v-if="orderDateils.ordersStatus >= 2"
                        :style="{ opacity: orderDateils.ordersStatus == 2 ? 1 : 0.5 }"
                      >
                        <div class="lin2"></div>
                        <div>
                          <i
                            :class="
                              orderDateils.ordersStatus == 2 ? 'el-icon-warning co_icons_active' : 'el-icon-success'
                            "
                            class="el-icon-success co_icons"
                          ></i>
                        </div>
                        <div class="complete_txtBox">
                          <div class="complete_txt">
                            {{
                              orderDateils.ordersStatus == 2
                                ? '待客户确认预约'
                                : orderDateils.ordersStatus > 2
                                ? '已确认预约'
                                : ''
                            }}
                          </div>
                          <div class="complete_time">{{ orderDateils.updateTime }}</div>
                        </div>
                      </div>
                      <div class="complete p_order_pace_items" v-if="orderDateils.ordersStatus >= 1">
                        <div class="lin1"></div>
                        <div>
                          <i class="el-icon-success co_icons"></i>
                        </div>
                        <div class="complete_txtBox">
                          <div class="complete_txt">
                            {{ orderDateils.spBusId == null ? '未接单' : '已接单' }}
                            <span> {{ spBusInfo?.spName }} </span>
                          </div>
                          <div class="complete_time">{{ orderDateils.updateTime }}</div>
                        </div>
                      </div>
                      <div class="complete p_order_pace_items">
                        <div>
                          <i class="el-icon-success co_icons"></i>
                        </div>
                        <div class="complete_txtBox">
                          <div class="complete_txt">{{ (orderDateils.payStatus = 1 ? '已购买' : '未支付') }}</div>
                          <div class="complete_time">{{ orderDateils.createTime }}</div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <el-col :span="9" class="bottomBtnBox">
                  <div class="bottomBtnInner">
                    <div class="botLeft">
                      <div class="botLeft_tit">订单金额：</div>
                      <div class="botLeft_tit" style="color: #09bebe; margin-right: 8px">¥</div>
                      <div class="botLeft_nunmber">{{ orderDateils.goodsAmount }}</div>
                    </div>
                  </div>
                </el-col>
              </div>
            </div>
          </el-col>
        </div>
      </el-row>
    </div>
    <!-- 查看服务详情 -->
    <orderDateils
      :orderDateils="orderDateilsShow"
      @closeOrderDateils="showOrderDateils"
      :orderDateilsInfo="orderDateils"
    ></orderDateils>
    <!-- 审核退款/售后 -->
    <confirmOrder
      :confirmOrder="confirmOrderOrderShow"
      @closeConfirmOrderOrder="showConfirmOrderOrder"
      :refundInfo="refundInfo"
      :getSpBusRefund="getSpBusRefund"
    ></confirmOrder>
  </div>
</template>
<script>
import '../../assets/css/element.css'
// 从session中获取token信息
import session from '@/utils/session.js'
import {
  findByGoodsId,
  findBySpBusIdOrdersStatus,
  getUserOrdersRefundBySpBusIdList,
  getOrderDetailsById,
  getSpBusRefund
} from '@/api/receivingOrders.js'
import { findSetType } from '@/api/spBusFinance.js'
import { spAreaCodeToCity } from '@/api/area.js'
import OrderDateils from '@/components/orders/orderDateils'
import ConfirmOrder from '@/components/orders/confirmOrder'
import NotCertifiedVue from '../../components/public/notCertified.vue'
import EmptyPageVue from '../../components/public/emptyPage.vue'
export default {
  components: {
    OrderDateils: OrderDateils,
    NotCertifiedVue,
    EmptyPageVue,
    ConfirmOrder: ConfirmOrder
  },
  data() {
    return {
      certificationStatus: session.getUserInfo().certificationStatus,
      spBusInfo: {},
      activeItems: 2,
      activeIndex: 0,
      activeName: 'confirm',
      dataList: [],
      orderDateils: {},
      goodsDateils: {},
      query: {
        page: 1,
        pageSize: 10
      },
      orderDateilsShow: false,
      total: 0,
      refundInfo: {},
      refundList: null,
      confirmOrderOrderShow: false,
      newAmount: 0
    }
  },
  mounted() {
    this.getPageSpBusId()
    this.spBusInfo = session.getUserInfo()
    this.spBusInfo = this.spBusInfo
  },
  methods: {
    async getPageSpBusId() {
      let res = await findBySpBusIdOrdersStatus(this.activeItems)
      if (res.success) {
        this.dataList = res.data
        if (this.dataList.length > 0) {
          this.total = this.dataList.length
        } else {
          this.total = 0
        }
        this.listCmputed()
        this.orderDateils = this.listCmputed()[0]
        this.openDateils(this.orderDateils, 0)
        this.getFindByOrderId()
        let that = this
        setTimeout(function () {
          that.onBtn()
        }, 500)
      }
    },
    listCmputed: function () {
      let index = this.activeItems
      return this.dataList.filter(function (item) {
        return item.ordersStatus == index
      })
    },
    handleClick(tab) {
      if (tab.index == 0) {
        // 清空退款售后列表
        this.refundList = null
        this.activeItems = 2
        this.activeIndex = 0
        this.getPageSpBusId()
      } else if (tab.index == 1) {
        // 清空退款售后列表
        this.refundList = null
        this.activeItems = 3
        this.getPageSpBusId()
        this.activeIndex = 0
      } else if (tab.index == 2) {
        // 清空退款售后列表
        this.refundList = null
        this.activeItems = 4
        this.getPageSpBusId()
        this.activeIndex = 0
      } else if (tab.index == 3) {
        this.activeItems = 5
        this.getPageSpBusId()
        this.getUserOrdersRefundBySpBusIdList()
        this.activeIndex = 0
      } else if (tab.index == 4) {
        // 清空退款售后列表
        this.refundList = null
        this.activeItems = 7
        this.getPageSpBusId()
        this.activeIndex = 0
      }
    },
    // 向iframe传值
    async onBtn() {
      let iframe = window.frames['addressData']
      let res = await spAreaCodeToCity(this.spBusInfo.spAreaId)
      let spJson = {
        province: res.data.province,
        city: res.data.city,
        area: res.data.area,
        spAddress: this.spBusInfo.spAddress
      }
      let data = {
        start: spJson,
        end: this.orderDateils
      }
      iframe.onChangeStr(data)
    },
    // 查询退款/售后里列表
    async getUserOrdersRefundBySpBusIdList() {
      let refundList = await getUserOrdersRefundBySpBusIdList()
      if (refundList.success) {
        this.refundList = refundList.data

        this.openRefundDateils(refundList.data[0])
      }
    },
    // 查看退款/售后详情
    async openRefundDateils(v, t) {
      this.activeIndex = t
      this.refundInfo = v
      // 查询订单详情
      let res = await getOrderDetailsById(v.userOrderId)
      if (res.success) {
        this.orderDateils = res.data
      }
    },
    // 查看默认详情
    async openDateils(items, index) {
      this.orderDateils = items
      this.activeIndex = index
      this.getFindByOrderId()
      this.onBtn()
    },
    // 审核退款/售后
    async openConfirmOrder() {
      this.confirmOrderOrderShow = true
    },
    showConfirmOrderOrder() {
      this.confirmOrderOrderShow = false
    },
    // 提交审核
    async getSpBusRefund(data) {
      let res = await getSpBusRefund(this.refundInfo.id, data)
    },
    // 根据goodsId获取商品信息
    async getFindByOrderId() {
      let res = await findByGoodsId(this.orderDateils.goodsId)
      if (res.success) {
        this.goodsDateils = res.data
      }
    },
    //显示查看服务详情
    openServiceDateils() {
      this.orderDateilsShow = true
    },
    showOrderDateils() {
      this.orderDateilsShow = false
    }
  }
}
</script>
<style lang="less" scoped>
.pageBox {
  width: 100%;
  height: calc(100vh - 60px);
  color: #202626;
  font-size: 14px;
  background: #f2f3f4;
  .pageTitBox {
    padding: 8px;
    .pageTitInner {
      background: #fff;
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 0 16px;
      span {
        padding-left: 40px;
      }
      .pageTit {
        font-size: 16px;
        font-weight: bold;
      }
    }
    .el-tabs__header {
      margin: 0;
    }
  }
  .pageList {
    margin-top: 8px;
    .grid-content {
      .pageListLeft {
        scrollbar-width: none;
        -ms-overflow-style: none;
        overflow-x: hidden;
        overflow-y: auto;
        height: calc(100vh - 158px);
      }
      .pageListLeft_items {
        display: flex;
        justify-content: space-between;
        align-items: center;
        background: #fff;
        padding: 16px;
        margin-bottom: 8px;
        .p_items_left {
          width: 100%;
        }
        .p_items_tit {
          width: 100%;
          font-size: 20px;
          font-weight: bold;
          cursor: pointer;
          display: flex;
          justify-content: space-between;
          align-items: center;
          .p_items_orderStatus {
            display: flex;
            align-items: center;
            font-size: 14px;
            font-weight: 400;
            color: #fff;
            border-radius: 4px;
            .pio_left {
              padding: 4px 8px;
              background: #204f4c;
              border-top-left-radius: 4px;
              border-bottom-left-radius: 4px;
            }
            .pio_right {
              padding: 4px 8px;
              background: #ffdd00;
              color: #204f4c;
              border-top-right-radius: 4px;
              border-bottom-right-radius: 4px;
            }
          }
        }
        .p_items_tit:hover {
          font-size: 20px;
          font-weight: bold;
          color: #09bebe;
          cursor: pointer;
        }
        .p_items_dec {
          font-size: 14px;
          opacity: 0.7;
          padding: 8px 0;
        }
        .p_items_tag {
          font-size: 14px;
          span {
            padding-right: 24px;
            i {
              margin-right: 8px;
            }
          }
        }
        .p_items_right {
          width: 40px;
          text-align: center;
          .el-icon-right {
            font-size: 20px;
          }
        }
      }
      .pageListRight {
        scrollbar-width: none;
        -ms-overflow-style: none;
        overflow-x: hidden;
        overflow-y: auto;
        background: #fff;
        height: calc(100vh - 138px);
        .p_mapBox {
          padding: 8px;
          .p_mapInner {
            height: 320px;
            background: #f2f3f4;
          }
        }
        .p_orderBox {
          .p_orderTit {
            padding: 8px 16px;
            font-size: 24px;
            font-weight: bold;
          }
          .userInfo {
            font-size: 16px;
            margin: 16px;
            padding: 16px;
            background: #e9fbf2;
            .userInfoInner {
              display: flex;
              align-items: center;
              justify-content: space-between;
              .userInfoLeft {
                display: flex;
                align-items: center;
              }
              .userName {
                margin-right: 24px;
              }
              .userCallDec {
                font-size: 12px;
                opacity: 0.7;
              }
            }
          }
          .p_orderStatus {
            padding: 8px 16px;
            display: flex;
            align-items: center;
            margin: 16px 0;
            .p_o_tit {
              color: #fff;
              padding: 8px 16px;
              background: #204f4c;
              font-size: 14px;
              border-top-left-radius: 4px;
              border-bottom-left-radius: 4px;
            }
            .p_o_dec {
              color: #204f4c;
              padding: 8px 16px;
              background: #ffdd00;
              font-size: 14px;
              border-top-right-radius: 4px;
              border-bottom-right-radius: 4px;
            }
          }
          .p_userOrderBox {
            margin: 16px;
            padding: 16px;
            background: #e9fbf2;
            font-size: 16px;
            color: #078b8b;
            div {
              padding: 4px 0;
            }
          }
          .p_o_info {
            padding: 0 16px;
            margin: 16px 0;
            .p_o_info_items {
              padding: 8px 0;
              .p_o_info_items_tit {
                opacity: 0.7;
              }
            }
          }
          .p_order_paceBox {
            padding: 0 16px;
            margin-bottom: 70px;
            .p_order_paceTit {
              font-size: 16px;
              font-weight: bold;
            }
            .p_order_paceInner {
              padding: 16px 0;
              position: relative;
              .lin1 {
                position: absolute;
                background: #e4e8e8;
                width: 1px;
                height: 40px;
                left: 20px;
                bottom: -20px;
                z-index: 998;
              }
              .lin2 {
                position: absolute;
                background: #e4e8e8;
                width: 1px;
                height: 40px;
                left: 20px;
                bottom: -20px;
                z-index: 998;
              }
              .lin3 {
                position: absolute;
                background: #e4e8e8;
                width: 1px;
                height: 40px;
                left: 20px;
                bottom: -20px;
                z-index: 998;
              }
              .lin4 {
                position: absolute;
                background: #e4e8e8;
                width: 1px;
                height: 40px;
                left: 20px;
                bottom: -20px;
                z-index: 998;
              }
              .lin5 {
                position: absolute;
                background: #e4e8e8;
                width: 1px;
                height: 40px;
                left: 20px;
                bottom: -20px;
                z-index: 998;
              }
              .lin6 {
                position: absolute;
                background: #e4e8e8;
                width: 1px;
                height: 40px;
                left: 20px;
                bottom: -20px;
                z-index: 998;
              }
              .lin7 {
                position: absolute;
                background: #e4e8e8;
                width: 1px;
                height: 40px;
                left: 20px;
                bottom: -20px;
                z-index: 998;
              }
              .p_order_pace_items {
                padding: 24px 0;
                margin-left: 24px;
              }
              .complete {
                display: flex;
                align-items: center;
                opacity: 0.5;
                position: relative;
                .co_icons {
                  font-size: 40px;
                  opacity: 0.5;
                }
                .co_icons_active {
                  color: #ff9300;
                  opacity: 1;
                }
                .complete_txtBox {
                  margin-left: 16px;
                  .complete_txt {
                    font-size: 16px;
                  }
                  .complete_time {
                    font-size: 12px;
                    padding-top: 4px;
                  }
                  .complete_details {
                    color: #09bebe;
                    cursor: pointer;
                  }
                  .complete_details:hover {
                    color: #078b8b;
                  }
                  .ct_btnBox {
                    width: 60px;
                    background: #09bebe;
                    padding: 4px 8px;
                    border-radius: 4px;
                    color: #fff;
                    text-align: center;
                  }
                }
              }
            }
          }
        }
        .bottomBtnBox {
          position: absolute;
          bottom: 0;
          background: #fff;
          z-index: 999;
          border-top-color: #e4e8e8;
          border-top-width: 1px;
          border-top-style: solid;
          width: 41%;
        }
        .bottomBtnInner {
          display: flex;
          justify-content: space-between;
          align-items: center;
          padding: 16px;
        }
        .botLeft {
          display: flex;
          align-items: flex-end;
          .botLeft_tit {
            font-size: 14px;
            padding-bottom: 4px;
            line-height: 14px;
          }
          .botLeft_nunmber {
            font-size: 24px;
            font-weight: bold;
            color: #09bebe;
          }
        }
        .botRight_btn {
          background: #09bebe;
          border-radius: 4px;
          font-size: 16px;
          color: #fff;
          padding: 8px 60px;
        }
      }
    }
  }
}
</style>
