<template>
  <div class="pageBox">
    <notCertifiedVue v-if="certificationStatus == 0" />
    <div v-if="certificationStatus == 1">
      <div class="adminPasswordBox" v-if="passwordShow == false">
        <div class="adminPasswordInner">
          <div class="adminTitBox">
            <div class="admin_tit">身份验证</div>
            <!-- <div>忘记密码？</div> -->
          </div>
          <el-form status-icon class="demo-ruleForm">
            <el-form-item>
              <el-input type="password" placeholder="请输入财务密码" v-model="password"></el-input>
            </el-form-item>
            <div class="btnBox">
              <el-button type="primary" style="width: 100%" @click="submitForm">提交</el-button>
            </div>
          </el-form>
        </div>
      </div>
      <div class="pageInner" v-if="passwordShow == true">
        <!-- 账户管理 -->
        <bankAccountManage :pageShow="bankAccountManageShow" :bankAccountManageClose="bankAccountManageClose" />
        <div class="baseInfoBox">
          <el-row style="width: 100%">
            <el-col :span="12" class="baseInfoLeft">
              <div class="busImg">
                <img src="../../assets/img/avatar_MS.png" alt="" />
              </div>
              <div class="baseInfoTxtBox">
                <div class="bus_name">
                  您好，{{ spBusInfo.spName }}
                  <span class="logOut" @click="logOut">安全退出</span>
                </div>
                <div class="bus_dec">
                  <div class="bus_id">
                    ID：<span>{{ spBusInfo.id }}</span>
                  </div>
                  <!-- <div>有效期至 2024-01-01</div> -->
                </div>
                <div>
                  地址：{{ areaCityProvince.province }}
                  {{ areaCityProvince.city }}
                  {{ areaCityProvince.area }}
                  {{ spBusInfo.spAddress }}
                </div>
              </div>
            </el-col>
            <el-col :span="12" class="baseInfoRight">
              <el-row style="width: 100%">
                <el-col :span="9" class="baser_items">
                  <div class="baser_itemTit">服务项目</div>
                  <div>{{ spBusInfo.serviceItems }}</div>
                </el-col>
                <el-col :span="9" class="baser_items">
                  <div class="baser_itemTit">服务区域</div>
                  <div>{{ spBusInfo.serviceArea }}</div>
                </el-col>
                <el-col :span="6" class="baser_items">
                  <div class="baser_itemTit">联系电话</div>
                  <div>{{ spBusInfo.busPhone }}</div>
                </el-col>
              </el-row>
            </el-col>
          </el-row>
        </div>
        <el-row :gutter="8" class="surveyBox">
          <el-col :span="24" class="sb_items">
            <div class="grid-content">
              <div class="grid_titBox">
                <div class="grid_tit">
                  收益概览
                  <span
                    style="font-size: 14px; font-weight: 400; padding-left: 16px; cursor: pointer"
                    @click="openRefresh"
                    ><i class="el-icon-refresh" style="padding-right: 4px"></i>刷新数据</span
                  >
                </div>
                <!-- <div class="grid_btn"><i class="el-icon-service"></i>联系菏康</div> -->
              </div>
              <div class="gc_inner">
                <div class="gci_left">
                  <div style="padding-bottom: 24px">
                    <div class="grid_nunmber_tit">
                      可用金额(元)
                      <span>
                        <el-tooltip
                          popper-class="tooltip_item"
                          effect="dark"
                          content="可用金额为订单交易完成金额，可用于提现等用途"
                          placement="top"
                        >
                          <i class="el-icon-warning-outline"></i> </el-tooltip
                      ></span>
                    </div>
                    <div class="grid_nunmber" style="color: #09bebe">{{ raeAmount }}</div>
                  </div>
                  <div>
                    <div class="grid_nunmber_tit">
                      不可用金额(元)
                      <span>
                        <el-tooltip
                          popper-class="tooltip_item"
                          effect="dark"
                          content="通常为订单进行中金额，交易完成可转为可用金额,此金额未扣加/减补差、补贴、退款、服务费等费用，且会实时更新"
                          placement="top"
                        >
                          <i class="el-icon-warning-outline"></i> </el-tooltip
                      ></span>
                    </div>
                    <div class="grid_nunmber" style="color: #09bebe">{{ financeInfo.notOrderAmount }}</div>
                    <div class="grid_nunmber_tit">{{ financeInfo.notOrderTotal }} 笔</div>
                  </div>
                  <div>
                    <el-button size="medium" @click="getToBusPay">提现</el-button>
                    <el-button size="medium" @click="openBankAccountManageShow">收款账户</el-button>
                  </div>
                </div>
                <el-row :span="24" class="gci_right">
                  <el-col :span="6" class="grid_mid_item">
                    <div class="grid_nunmber_tit">
                      成交订单(元)
                      <span>
                        <el-tooltip
                          popper-class="tooltip_item"
                          effect="dark"
                          content="交易完成的订单金额汇总，此金额未扣加/减补差、补贴、服务费，且会实时更新"
                          placement="top"
                        >
                          <i class="el-icon-warning-outline"></i> </el-tooltip
                      ></span>
                    </div>
                    <div class="grid_nunmber">{{ financeInfo.orderAmount }}</div>
                    <div class="grid_nunmber_tit">{{ financeInfo.orderTotal }} 笔</div>
                  </el-col>
                  <el-col :span="6" class="grid_mid_item">
                    <div class="grid_nunmber_tit">
                      交易完成补差金额(元)
                      <span>
                        <el-tooltip
                          popper-class="tooltip_item"
                          effect="dark"
                          content="交易完成的订单补差金额汇总，此金额未扣减服务费，且会实时更新"
                          placement="top"
                        >
                          <i class="el-icon-warning-outline"></i> </el-tooltip
                      ></span>
                    </div>
                    <div class="grid_nunmber">{{ financeInfo.serviceFee }}</div>
                    <div class="grid_nunmber_tit">{{ financeInfo.serviceTotal }} 笔</div>
                  </el-col>
                  <el-col :span="6" class="grid_mid_item">
                    <div class="grid_nunmber_tit">
                      交易完成补贴金额(元)
                      <span>
                        <el-tooltip
                          popper-class="tooltip_item"
                          effect="dark"
                          content="交易完成的订单补贴金额汇总，此金额通常为订单售后由平台扣除的金额汇总，且会实时更新"
                          placement="top"
                        >
                          <i class="el-icon-warning-outline"></i> </el-tooltip
                      ></span>
                    </div>
                    <div class="grid_nunmber">{{ financeInfo.serviceFee }}</div>
                    <div class="grid_nunmber_tit">{{ financeInfo.serviceTotal }} 笔</div>
                  </el-col>
                  <el-col :span="6" class="grid_mid_item">
                    <div class="grid_nunmber_tit">
                      服务费金额(元)
                      <span>
                        <el-tooltip
                          popper-class="tooltip_item"
                          effect="dark"
                          content="交易完成的订单已经收取的服务费金额汇总，此金额已扣减退服务费金额，且会实时更新"
                          placement="top"
                        >
                          <i class="el-icon-warning-outline"></i> </el-tooltip
                      ></span>
                    </div>
                    <div class="grid_nunmber">{{ financeInfo.serviceFee }}</div>
                    <div class="grid_nunmber_tit">{{ financeInfo.serviceTotal }} 笔</div>
                  </el-col>
                  <el-col :span="6" class="grid_mid_item">
                    <div class="grid_nunmber_tit">
                      退款订单(笔)
                      <span>
                        <el-tooltip
                          popper-class="tooltip_item"
                          effect="dark"
                          content="交易完成的订单中已退款订单金额汇总，且会实时更新"
                          placement="top"
                        >
                          <i class="el-icon-warning-outline"></i> </el-tooltip
                      ></span>
                    </div>
                    <div class="grid_nunmber">{{ financeInfo.refundAmount }}</div>
                    <div class="grid_nunmber_tit">{{ financeInfo.refundTotal }} 笔</div>
                  </el-col>
                  <el-col :span="6" class="grid_mid_item">
                    <div class="grid_nunmber_tit">
                      交易中补差金额(元)
                      <span>
                        <el-tooltip
                          popper-class="tooltip_item"
                          effect="dark"
                          content="交易中的订单补差金额汇总，此金额未扣减服务费，且会实时更新"
                          placement="top"
                        >
                          <i class="el-icon-warning-outline"></i> </el-tooltip
                      ></span>
                    </div>
                    <div class="grid_nunmber">{{ financeInfo.notRepairAmount }}</div>
                    <div class="grid_nunmber_tit">{{ financeInfo.notRepairTotal }} 笔</div>
                  </el-col>
                  <el-col :span="6" class="grid_mid_item">
                    <div class="grid_nunmber_tit">
                      交易中补贴金额(元)
                      <span>
                        <el-tooltip
                          popper-class="tooltip_item"
                          effect="dark"
                          content="交易中的订单补贴金额汇总，此金额通常为订单售后由平台扣除的金额汇总，且会实时更新"
                          placement="top"
                        >
                          <i class="el-icon-warning-outline"></i> </el-tooltip
                      ></span>
                    </div>
                    <div class="grid_nunmber">{{ financeInfo.notSubsidyAmount }}</div>
                    <div class="grid_nunmber_tit">{{ financeInfo.notSubsidyTotal }} 笔</div>
                  </el-col>
                </el-row>
              </div>
            </div>
          </el-col>
          <el-col :span="24" class="sb_items">
            <el-col :span="12" class="sb_items_el_col">
              <div class="grid-content">
                <div class="grid_tit">订单概览</div>
                <div class="grid_mid">
                  <el-col :span="5" class="grid_mid_item">
                    <div class="grid_nunmber_tit">完成总量</div>
                    <div class="grid_nunmber">{{ ordersDateInfo.ordersFulfil }}</div>
                  </el-col>
                  <el-col :span="5" class="grid_mid_item">
                    <div class="grid_nunmber_tit">取消总量</div>
                    <div class="grid_nunmber">{{ ordersDateInfo.ordersCancel }}</div>
                  </el-col>
                  <el-col :span="5" class="grid_mid_item">
                    <div class="grid_nunmber_tit">退款总量</div>
                    <div class="grid_nunmber">{{ ordersDateInfo.ordersDrawback }}</div>
                  </el-col>
                  <el-col :span="5" class="grid_mid_item">
                    <div class="grid_nunmber_tit">累计总量</div>
                    <div class="grid_nunmber">{{ ordersDateInfo.ordersTotal }}</div>
                  </el-col>
                </div>
              </div>
            </el-col>
            <el-col :span="12" class="sb_items_el_col">
              <div class="grid-content">
                <div class="grid_tit">进行中</div>
                <div class="grid_mid">
                  <el-col :span="5">
                    <div class="grid_nunmber_tit">确认预约</div>
                    <div class="grid_nunmber">{{ ordersDateInfo.ordersConfirmMake }}</div>
                  </el-col>
                  <el-col :span="5">
                    <div class="grid_nunmber_tit">服务中</div>
                    <div class="grid_nunmber">{{ ordersDateInfo.ordersServiceStart }}</div>
                  </el-col>
                  <el-col :span="5">
                    <div class="grid_nunmber_tit">
                      服务结束
                      <span>
                        <el-tooltip
                          popper-class="tooltip_item"
                          effect="dark"
                          content="服务结束仅为提供服务完成，并非订单完成"
                          placement="top"
                        >
                          <i class="el-icon-warning-outline"></i> </el-tooltip
                      ></span>
                    </div>
                    <div class="grid_nunmber">{{ ordersDateInfo.ordersServiceEnd }}</div>
                  </el-col>
                </div>
              </div>
            </el-col>
          </el-col>
        </el-row>
        <el-row :gutter="8" class="dataSheetBox">
          <el-col :span="24">
            <div class="grid-content">
              <div class="grid_tit">资金流水财务明细</div>
              <div class="grid_data">
                <el-table :data="tableData1.rows" height="390" style="width: 100%">
                  <el-table-column prop="createTime" label="入账日期" :show-overflow-tooltip="true"> </el-table-column>
                  <el-table-column prop="id" label="账单id" :show-overflow-tooltip="true"> </el-table-column>
                  <el-table-column prop="refundStatus" label="对方信息">
                    <template slot-scope="scope">
                      <p style="margin: 0">
                        {{ !!scope.row.busPayAccount ? mobileStr(scope.row.busPayAccount) : '-' }}
                      </p>
                      <p style="margin: 0">
                        {{ !!scope.row.busPayName ? userNameStr(scope.row.busPayName) : '-' }}
                      </p>
                    </template>
                  </el-table-column>
                  <el-table-column prop="type" label="财务类型" :show-overflow-tooltip="true">
                    <template slot-scope="scope">
                      <p>
                        {{ scope.row.type == 0 ? '收入' : '支出' }}
                      </p>
                    </template>
                  </el-table-column>
                  <el-table-column prop="amount" label="收支金额" :show-overflow-tooltip="true">
                    <template slot-scope="scope">
                      <p v-if="scope.row.type == 0" style="color: #09be64">
                        {{ '+' + scope.row.amount }}
                      </p>
                      <p v-if="scope.row.type == 1" style="color: #dd2424">
                        {{ '-' + scope.row.amount }}
                      </p>
                    </template>
                  </el-table-column>
                  <el-table-column prop="raeAmount" label="账户余额" :show-overflow-tooltip="true"> </el-table-column>
                  <el-table-column prop="rate" label="费率" :show-overflow-tooltip="true">
                    <template slot-scope="scope">
                      <p>
                        {{ !!scope.row.rate ? scope.row.rate : '-' }}
                      </p>
                    </template>
                  </el-table-column>
                  <el-table-column prop="serviceFee" label="手续费" :show-overflow-tooltip="true">
                    <template slot-scope="scope">
                      <p>
                        {{ !!scope.row.serviceFee ? scope.row.serviceFee : '-' }}
                      </p>
                    </template>
                  </el-table-column>
                  <el-table-column prop="remarks" label="备注" :show-overflow-tooltip="true"> </el-table-column>
                </el-table>
                <div class="table_page">
                  <el-pagination
                    @size-change="handleSizeChange"
                    @current-change="handleCurrentChange"
                    layout="prev, pager, next"
                    :total="tableData.total"
                  >
                  </el-pagination>
                </div>
              </div>
            </div>
          </el-col>
          <el-col :span="24">
            <div class="grid-content">
              <div class="grid_tit">商家接单明细</div>
              <div class="grid_data">
                <el-table :data="tableData.rows" height="390" style="width: 100%">
                  <el-table-column prop="createTime" label="接单日期" :show-overflow-tooltip="true"> </el-table-column>
                  <el-table-column prop="orderId" label="服务订单id" :show-overflow-tooltip="true"> </el-table-column>
                  <el-table-column prop="orderAmount" label="订单金额" :show-overflow-tooltip="true"> </el-table-column>
                  <el-table-column prop="repairAmount" label="补差价金额" :show-overflow-tooltip="true">
                  </el-table-column>
                  <el-table-column prop="subsidyAmount" label="补贴金额" :show-overflow-tooltip="true">
                  </el-table-column>
                  <el-table-column prop="refundStatus" label="退款状态">
                    <template slot-scope="scope">
                      <p>
                        {{ scope.row.refundStatus == 0 ? '无' : '已退款' }}
                      </p>
                    </template>
                  </el-table-column>
                  <el-table-column prop="status" label="交易状态">
                    <template slot-scope="scope">
                      <p>
                        {{ scope.row.status == 0 ? '交易中' : '交易完成' }}
                      </p>
                    </template>
                  </el-table-column>
                  <el-table-column prop="rate" label="费率" :show-overflow-tooltip="true"> </el-table-column>
                  <el-table-column prop="serviceFee" label="服务费" :show-overflow-tooltip="true">
                    <template slot-scope="scope">
                      <p>
                        {{ !!scope.row.serviceFee ? scope.row.serviceFee : '-' }}
                      </p>
                    </template>
                  </el-table-column>
                  <!-- <el-table-column prop="address" label="操作">
                  <template slot-scope="scope">
                    <el-button @click.native.prevent="deleteRow(scope.$index, tableData)" type="text" size="small">
                      移除
                    </el-button>
                  </template>
                </el-table-column> -->
                </el-table>
                <div class="table_page">
                  <el-pagination
                    @size-change="handleSizeChange"
                    @current-change="handleCurrentChange"
                    layout="prev, pager, next"
                    :total="tableData.total"
                  >
                  </el-pagination>
                </div>
              </div>
            </div>
          </el-col>
          <el-col :span="24">
            <div class="grid-content">
              <div class="grid_tit">订单明细</div>
              <div class="grid_data">
                <el-table :data="ordersList.rows" height="390" style="width: 100%">
                  <el-table-column label="订单名称" :show-overflow-tooltip="true">
                    <template slot-scope="scope">
                      {{ JSON.parse(scope.row.goodsContent).serviceTitle }}
                    </template>
                  </el-table-column>
                  <el-table-column label="订单状态" :show-overflow-tooltip="true">
                    <template slot-scope="scope">
                      {{
                        scope.row.ordersStatus == 2
                          ? '待客户确认'
                          : scope.row.ordersStatus == 3
                          ? '商家服务'
                          : scope.row.ordersStatus == 4
                          ? '已完成'
                          : scope.row.ordersStatus == 5
                          ? '已退款'
                          : scope.row.ordersStatus == 7
                          ? '已取消'
                          : '-'
                      }}
                    </template>
                  </el-table-column>
                  <el-table-column label="服务时间" :show-overflow-tooltip="true">
                    <template slot-scope="scope">
                      {{ JSON.parse(scope.row.goodsContent).date }}
                      {{ JSON.parse(scope.row.goodsContent).time }}
                    </template>
                  </el-table-column>
                  <el-table-column prop="updateTime" label="更新时间" :show-overflow-tooltip="true"> </el-table-column>
                </el-table>
                <div class="table_page">
                  <el-pagination
                    @size-change="handleSizeChangeOrdersList"
                    @current-change="handleCurrentChangeOrdersList"
                    layout="prev, pager, next"
                    :total="ordersList.total"
                  >
                  </el-pagination>
                </div>
              </div>
            </div>
          </el-col>
        </el-row>
      </div>
    </div>
  </div>
</template>
<script>
// 从session中获取token信息
import session from '@/utils/session.js'
import { getByIdBusInfo } from '@/api/spBusInfo.js'
import {
  findBySpBusIdPageAll,
  findBySpBusBillsPageAll,
  spBusDataInfo,
  findSpBusDate,
  findByNewInfo
} from '@/api/spBusFinance.js'
import { getAreaCityProvince } from '@/api/public.js'
import { pageSpBusId } from '@/api/receivingOrders.js'
import NotCertifiedVue from '../../components/public/notCertified.vue'
import BankAccountManage from './bankAccountManage.vue'
export default {
  components: {
    NotCertifiedVue: NotCertifiedVue,
    BankAccountManage
  },
  data() {
    return {
      certificationStatus: session.getUserInfo().certificationStatus,
      password: '',
      passwordShow: false,
      spBusInfo: {},
      ordersTotal: 0,
      ordersList: [],
      tableData: [],
      tableData1: [],
      areaCityProvince: {},
      query: {
        page: 1,
        pageSize: 20
      },
      financeInfo: {},
      raeAmount: 0, // 账户余额
      ordersDateInfo: {},
      bankAccountManageShow: false
    }
  },
  created() {},
  mounted() {},
  methods: {
    // 刷新
    openRefresh() {
      console.log('我刷新了')
      this.getByIdBusInfo()
      this.getSpBusArea()
      this.getBusBillInfo()
      this.getBusDateInfo()
      this.getspBusDate()
      this.getSpBusBillsPageAll()
    },
    // 验证财务密码
    async submitForm() {
      let spBusInfo = session.getUserInfo()
      let res = await getByIdBusInfo(spBusInfo.id)
      if (res.success) {
        let password = res.data.financePassword
        if (this.password == password) {
          this.passwordShow = true
          this.$message({
            message: '验证成功',
            type: 'success'
          })
          this.password = ''
          this.spBusInfo = res.data
          this.getByIdBusInfo()
          this.getSpBusArea()
          this.getBusBillInfo()
          this.getBusDateInfo()
          this.getspBusDate()
          this.getSpBusBillsPageAll()
        } else {
          this.$message.error('密码输入有误，验证失败！')
          this.password = ''
        }
      } else {
        this.$message.error('请求失败，请稍后再试！')
      }
    },
    async getSpBusArea() {
      let ress = await getAreaCityProvince(this.spBusInfo.spAreaId)
      if (ress.success) {
        this.areaCityProvince = ress.data
      }
    },
    // 安全退出
    logOut() {
      this.passwordShow = false
      this.$message({
        message: '退出成功',
        type: 'success'
      })
    },
    // 获取财务数据
    async getspBusDate() {
      // 数据统计
      let res = await findSpBusDate()
      if (res.success) {
        this.financeInfo = res.data[0]
      }
      let ress = await findByNewInfo()
      if (ress.success) {
        this.raeAmount = ress.data.raeAmount
      }
    },
    // 获取订单信息
    async getByIdBusInfo() {
      let res = await pageSpBusId(this.query)
      if (res.success) {
        this.ordersList = res.data
      }
    },
    //分页-size
    handleSizeChangeOrdersList(val) {
      this.query.pageSize = val
      this.getByIdBusInfo()
    },
    //分页-page
    handleCurrentChangeOrdersList(val) {
      this.query.page = val
      this.getByIdBusInfo()
    },
    // 服务商资金流水财务明细
    async getBusBillInfo() {
      // 接单明细
      let res = await findBySpBusIdPageAll(this.query)
      if (res.success) {
        this.tableData = res.data
        this.tableData.rows.reverse()
      }
    },
    //分页-size
    handleSizeChange(val) {
      this.query.pageSize = val
      this.getBusBillInfo()
    },
    //分页-page
    handleCurrentChange(val) {
      this.query.page = val
      this.getBusBillInfo()
    },
    // 服务商资金流水财务明细息
    async getSpBusBillsPageAll() {
      // 接单明细
      let res = await findBySpBusBillsPageAll(this.query)
      if (res.success) {
        this.tableData1 = res.data
        this.tableData1.rows.reverse()
      }
    },
    //分页-size
    handleSizeChange(val) {
      this.query.pageSize = val
      this.getBusBillInfo()
    },
    //分页-page
    handleCurrentChange(val) {
      this.query.page = val
      this.getBusBillInfo()
    },
    // 获取订单概况
    async getBusDateInfo() {
      let res = await spBusDataInfo()
      if (res.success) {
        this.ordersDateInfo = res.data
      }
    },
    // 商家提现
    getToBusPay() {
      let routeData = this.$router.resolve({ path: '/sysToSpBusPay', query: { raeAmount: this.raeAmount } })
      window.open(routeData.href, '_blank')
    },
    // 查看收款账户
    openBankAccountManageShow() {
      this.bankAccountManageShow = !this.bankAccountManageShow
    },
    bankAccountManageClose(data) {
      this.bankAccountManageShow = data
    }
  }
}
</script>
<style lang="less" scoped>
.pageBox {
  width: 100%;
  height: calc(100vh - 60px);
  color: #202626;
  font-size: 14px;
  background: #f2f3f4;
  scrollbar-width: none;
  -ms-overflow-style: none;
  overflow-x: hidden;
  overflow-y: auto;
}
.adminPasswordBox {
  .adminPasswordInner {
    position: absolute;
    background: #fff;
    border-radius: 8px;
    width: 400px;
    padding: 24px;
    top: 30%;
    left: 40%;
    .adminTitBox {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
    .admin_tit {
      font-size: 16px;
      font-weight: bold;
    }
    .demo-ruleForm {
      padding-top: 24px;
    }
    .btnBox {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
}
.pageInner {
  padding: 8px;
}
.baseInfoBox {
  padding: 16px;
  background: #fff;
  display: flex;
  justify-content: space-between;
  align-items: center;
  .baseInfoLeft {
    display: flex;
    align-items: center;
    .busImg {
      width: 60px;
      height: 60px;
    }
    .baseInfoTxtBox {
      padding-left: 24px;
      .bus_name {
        font-size: 16px;
        font-weight: bold;
      }
      .bus_dec {
        font-size: 12px;
        display: flex;
        align-items: center;
        .bus_id {
          padding: 4px 0;
          padding-right: 24px;
        }
      }
      .logOut {
        margin-left: 8px;
        font-size: 12px;
        padding: 4px 16px;
        border: #09bebe 1px solid;
        border-radius: 4px;
        color: #09bebe;
        cursor: pointer;
      }
      .logOut:hover {
        margin-left: 8px;
        font-size: 12px;
        padding: 4px 16px;
        background: #09bebe;
        border-radius: 4px;
        color: #fff;
        cursor: pointer;
      }
    }
  }
  .baseInfoRight {
    display: flex;
    align-items: center;
    .baser_items {
      padding-left: 60px;
      .baser_itemTit {
        font-size: 14px;
        opacity: 0.7;
        padding-bottom: 4px;
      }
    }
  }
}
.displayFlex {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.sb_items {
  margin-bottom: 8px;
  .sb_items_el_col {
    padding: 0 !important;
    .grid_mid {
      padding-top: 16px;
    }
  }
  .gc_inner {
    display: flex;
    .gci_left {
      width: 240px;
      background: #f2f3f4;
      border-radius: 16px;
      padding: 24px;
    }
    .gci_right {
      width: 100%;
      padding: 0 24px;
    }
    .grid_mid_item {
      padding-top: 16px;
    }
  }
}
.surveyBox,
.dataSheetBox {
  padding-top: 8px;
  .grid-content {
    background: #fff;
    padding: 16px;
    .grid_titBox {
      display: flex;
      align-content: center;
      justify-content: space-between;
      margin-bottom: 16px;
      .grid_btn {
        font-size: 14px;
        .el-icon-service {
          font-size: 16px;
          padding-right: 4px;
        }
      }
    }
    .grid_tit {
      font-size: 16px;
      font-weight: bold;
    }
    .grid_data {
      padding-top: 12px;
      .table_page {
        display: flex;
        align-items: center;
        justify-content: end;
        padding-top: 8px;
      }
    }
    .grid_mid {
      display: flex;
      flex-wrap: wrap;
    }
    .grid_nunmber_tit {
      font-size: 14px;
      opacity: 0.7;
      padding-bottom: 8px;
    }
    .grid_nunmber {
      font-size: 24px;
      font-weight: bold;
    }
    .bottomBox {
      border-top-color: #e4e8e8;
      border-top-width: 1px;
      border-top-style: solid;
      padding-top: 24px;
      .bottomTit {
        font-size: 16px;
        font-weight: bold;
      }
      .bottomDec {
        font-size: 14px;
        font-weight: 400;
        opacity: 0.7;
      }
    }
  }
}
</style>
<style>
.tooltip_item {
  max-width: 200px;
  line-height: 1.7;
}
</style>