<template>
  <el-container class="pageBox">
    <el-header class="headerBox">
      <div class="title">菏康 ｜ 帮助中心文档</div>
    </el-header>
    <el-container class="container">
      <el-main class="mainBox">
        <div class="leftBox">
          <div class="lb_tit">菏康家政服务</div>
          <div class="lb_content">
            <div v-for="(items, index) in knowList" :key="index" @click="openKnowClass(items, index)">
              <div class="lbc_items" :class="{ showIndex: showIndex == index }">{{ items.title }}</div>
            </div>
          </div>
        </div>
        <div class="rightBox">
          <div v-html="knowDetatils.content"></div>
        </div>
      </el-main>
    </el-container>
    <div class="footerBox">
      <div class="f_innter">
        <div class="logoBox">
          <img src="../../assets/img/logo_cyan.svg" />
        </div>
        <div class="copyright">
          <div class="c_text">@2022 菏康 版权所有</div>
          <div class="c_text c_gongan">
            <a
              target="_blank"
              href="https://beian.miit.gov.cn"
              style="display: inline-block; text-decoration: none; height: 20px; line-height: 20px"
            >
              <p style="float: left; height: 20px; line-height: 20px; margin: 0px 0px 0px 5px">鲁ICP备2022031898号-1</p>
            </a>
          </div>
          <div class="c_text c_gongan">
            <div class="c_beianImg">
              <img src="../../assets/img/beian.png" style="float: left" />
            </div>
            <a
              target="_blank"
              href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=37172702371748"
              style="display: inline-block; text-decoration: none; height: 20px; line-height: 20px"
            >
              <p style="float: left; height: 20px; line-height: 20px; margin: 0px 0px 0px 5px">
                鲁公网安备 37172702371748号
              </p>
            </a>
          </div>
        </div>
      </div>
    </div>
  </el-container>
</template>
<script>
import { getKnowClassDisplayTerminalList, getKnowPageAll } from '@/api/public.js'
export default {
  data() {
    return {
      showIndex: 0,
      knowList: [],
      knowDetatils: {},
      query: {
        page: 1,
        pageSize: 50
      }
    }
  },
  watch: {},
  mounted() {
    this.getKnowClassInfo()
  },
  methods: {
    async getKnowClassInfo() {
      var displayTerminal = 3
      let res = await getKnowClassDisplayTerminalList(displayTerminal)
      if (res.success) {
        let filteredData = res.data.filter((i) => i.className == '商家帮助中心')
        let knowRes = await getKnowPageAll(filteredData[0].id, this.query)
        if (knowRes.success) {
          knowRes.data.rows.sort((a, b) => new Date(a.createTime).getTime() - new Date(b.createTime).getTime())
          this.knowList = knowRes.data.rows
          this.knowDetatils = this.knowList[0]
        }
      }
    },
    // 获取详情
    openKnowClass(items, index) {
      this.showIndex = index
      this.knowDetatils = items
    }
  }
}
</script>
<style lang="less" scoped>
.pageBox {
  width: 100vw;
  height: 100vh;
  background: #f2f3f4;
}
.headerBox {
  background: #09bebe;
  display: flex;
  justify-content: space-between;
  position: fixed;
  width: 100vw;
  z-index: 999;
  .title {
    font-size: 20px;
    font-weight: 500;
    text-align: left;
    color: #ffffff;
    line-height: 60px;
  }
}
.container {
  width: 1200px;
  margin: 0 auto;
  .mainBox {
    margin-top: 70px;
    height: calc(100vh - 70px);
    display: flex;
    justify-content: space-between;
    .leftBox {
      width: 228px;
      background: #ffffff;
      border-radius: 4px;
      overflow: hidden;
      .lb_tit {
        font-size: 18px;
        text-align: center;
        padding: 16px 0;
        background: #09bebe;
        color: #ffffff;
      }
      .lbc_items {
        padding: 16px;
        font-size: 14px;
      }
      .lbc_items:hover {
        color: #09bebe;
        cursor: pointer;
      }
      .showIndex {
        color: #09bebe;
      }
    }
    .rightBox {
      width: calc(1200px - 284px);
      background: #ffffff;
      border-radius: 4px;
      padding: 24px;
      overflow: inherit;
    }
  }
}
.footerBox {
  height: 60px;
  background: #2e3037;
  .f_innter {
    max-width: 1200px;
    margin: 0 auto;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 8px 0;
    color: #666;
  }
  a {
    color: #666;
  }
  .copyright {
    display: flex;
    align-items: center;
  }
  .c_text {
    padding-left: 24px;
  }
  .c_gongan {
    display: flex;
    align-items: center;
  }
  .c_beianImg {
    width: 16px;
    height: 16px;
  }
  img {
    width: 100%;
    height: 100%;
  }
  .logoBox {
    width: 76px;
    height: 30px;
  }
}
</style>
