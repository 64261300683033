<template>
  <div class="pageBox">
    <emptyPageVue v-if="this.dataList.length < 1" />
    <notCertifiedVue v-if="certificationStatus == 0" />
    <div v-if="certificationStatus == 1 && this.dataList.length > 0" class="pageTitBox">
      <div class="pageTitInner">
        <div class="pageTit">接单大厅</div>
        <!-- <div class="pageTitRight">
          <span>今日订单：8,763单</span>
          <span>待接订单：8,763单</span>
          <span>历史订单总量：8,763单</span>
          <span>订单总额：77,364元</span>
        </div> -->
      </div>
      <el-row :gutter="8" class="pageList">
        <el-col :span="14">
          <div class="grid-content bg-purple">
            <div class="pageListLeft">
              <div v-for="(items, index) in dataList" :key="index">
                <div class="pageListLeft_items" :style="{ border: index == activeIndex ? '#09bebe 2px solid' : '' }">
                  <div class="p_items_left">
                    <div class="p_items_tit" @click="openDateils(items, index)">
                      {{ JSON.parse(items.goodsContent).serviceTitle }}
                    </div>
                    <div class="p_items_dec">
                      {{ JSON.parse(items.goodsContent).serviceDec }}
                    </div>
                    <div class="p_items_tag">
                      <span
                        ><i class="el-icon-location"></i>{{ JSON.parse(items.addressInfo).userProvince
                        }}{{ JSON.parse(items.addressInfo).userCity }}{{ JSON.parse(items.addressInfo).userArea }}</span
                      >
                      <span><i class="el-icon-time"></i>{{ items.createTime }}</span>
                    </div>
                  </div>
                  <div class="p_items_right">
                    <i class="el-icon-right" :style="{ color: index == activeIndex ? '#09bebe' : '' }"></i>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </el-col>
        <el-col :span="10">
          <div class="grid-content bg-purple">
            <div class="pageListRight">
              <div class="p_mapBox">
                <div class="p_mapInner">
                  <!-- 地图显示 -->
                  <iframe
                    src="baiduMap/index.html"
                    scrolling="no"
                    style="width: 100%; height: 320px"
                    name="addressData"
                    frameborder="0"
                  ></iframe>
                </div>
              </div>
              <div class="p_orderBox">
                <div>
                  <div class="p_orderTit">
                    {{ JSON.parse(orderDateils.goodsContent).serviceTitle }}
                  </div>
                  <div class="p_orderStatus">
                    <div class="p_o_tit">订单状态</div>
                    <div class="p_o_dec">
                      {{
                        orderDateils.ordersStatus == 1
                          ? '待商家接单'
                          : orderDateils.ordersStatus == 2
                          ? '待用户确认预约'
                          : orderDateils.ordersStatus == 3
                          ? '商家服务'
                          : orderDateils.ordersStatus == 4
                          ? '已完成'
                          : orderDateils.ordersStatus == 5
                          ? '已退款'
                          : orderDateils.ordersStatus == 7
                          ? '取消订单'
                          : ''
                      }}
                    </div>
                  </div>
                </div>
                <div class="p_userOrderBox">
                  <div>
                    <span>上门地址：</span>
                    <span>
                      {{ JSON.parse(orderDateils.addressInfo).userProvince }}
                      {{ JSON.parse(orderDateils.addressInfo).userCity }}
                      {{ JSON.parse(orderDateils.addressInfo).userArea }}
                    </span>
                  </div>
                  <div>
                    <span>上门时间：</span>
                    <span>
                      {{ JSON.parse(orderDateils.goodsContent).date }}
                      {{ JSON.parse(orderDateils.goodsContent).time }}
                    </span>
                  </div>
                  <div v-if="JSON.parse(orderDateils.goodsContent)?.orderCleanArea">
                    <span>清洁面积：</span>
                    <span> {{ JSON.parse(orderDateils.goodsContent)?.orderCleanArea }}</span>
                  </div>
                  <div>
                    <span>计费方式</span>
                    <span> {{ JSON.parse(orderDateils.goodsContent)?.orderServiceTime }}</span>
                  </div>
                </div>
                <div class="p_o_info">
                  <div class="p_o_i_tit">服务内容</div>
                  <div
                    class="p_o_info_items"
                    v-for="(items, index) in JSON.parse(goodsDateils.serviceContent)?.domains"
                    :key="index"
                  >
                    <el-row>
                      <el-col :span="4" class="p_o_info_items_tit">{{ items.value }}:</el-col>
                      <el-col :span="20">
                        {{ items.label }}
                      </el-col>
                    </el-row>
                  </div>
                  <div class="p_o_info_items">
                    <el-row>
                      <el-col :span="4" class="p_o_info_items_tit">可选清洁面积:</el-col>
                      <el-col :span="20">
                        <span
                          style="padding-right: 16px"
                          v-for="(items, index) in JSON.parse(goodsDateils.serviceContent)?.cleanArea"
                          :key="index"
                        >
                          {{ items.value }}
                        </span>
                      </el-col>
                    </el-row>
                  </div>
                  <div class="p_o_info_items">
                    <el-row>
                      <el-col :span="4" class="p_o_info_items_tit">可选清洁时长:</el-col>
                      <el-col :span="20">
                        <span
                          style="padding-right: 16px"
                          v-for="(items, index) in JSON.parse(goodsDateils.serviceContent)?.serviceDuration"
                          :key="index"
                        >
                          {{ items.value }}
                        </span>
                      </el-col>
                    </el-row>
                  </div>
                  <div class="p_o_i_tit">注意事项：</div>
                  <div
                    class="p_o_info_items"
                    v-for="(items, index) in JSON.parse(goodsDateils.serviceContent)?.lookMatter"
                    :key="index"
                  >
                    <el-row>
                      <el-col :span="4" class="p_o_info_items_tit">{{ items.value }}:</el-col>
                      <el-col :span="20">
                        {{ items.label }}
                      </el-col>
                    </el-row>
                  </div>
                </div>
                <div class="p_order_paceBox">
                  <div class="p_order_paceTit">订单进度</div>
                  <div class="p_order_paceInner">
                    <div class="lin"></div>
                    <div class="pendingOrder p_order_pace_items">
                      <div>
                        <i class="el-icon-warning"></i>
                      </div>
                      <div class="purchased_txtBox">
                        <div class="purchased_txt">待商家接单</div>
                        <div class="purchased_time">{{ orderDateils.updateTime }}</div>
                      </div>
                    </div>
                    <div class="purchased p_order_pace_items">
                      <div>
                        <i class="el-icon-success"></i>
                      </div>
                      <div class="purchased_txtBox">
                        <div class="purchased_txt">已购买</div>
                        <div class="purchased_time">{{ orderDateils.createTime }}</div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <el-col :span="9" class="bottomBtnBox">
                <div class="bottomBtnInner">
                  <div class="botLeft">
                    <div class="botLeft_tit">订单金额：</div>
                    <div class="botLeft_tit" style="color: #09bebe; margin-right: 8px">¥</div>
                    <div class="botLeft_nunmber">{{ orderDateils.goodsAmount }}</div>
                  </div>
                  <div class="botRight">
                    <el-button type="primary" @click="openReceivingOrders()">立即抢单</el-button>
                  </div>
                </div>
              </el-col>
            </div>
          </div>
        </el-col>
      </el-row>
    </div>
  </div>
</template>
<script>
import session from '@/utils/session'
import { pageSpCityIdAll, findByGoodsId, spReceivingOrders } from '@/api/receivingOrders.js'
import { spAreaCodeToCity } from '@/api/area.js'
import NotCertifiedVue from '../../components/public/notCertified.vue'
import EmptyPageVue from '../../components/public/emptyPage.vue'
export default {
  components: {
    NotCertifiedVue,
    EmptyPageVue
  },
  data() {
    return {
      certificationStatus: session.getUserInfo()?.certificationStatus,
      activeIndex: 0,
      dataList: [],
      orderDateils: {},
      goodsDateils: {},
      newAmount: 0
    }
  },
  created() {},
  mounted() {
    this.getOrdersList()
    this.getSetType()
  },
  methods: {
    // 初始
    async getOrdersList() {
      let result = await pageSpCityIdAll()
      if (result.success) {
        this.dataList = result.data
        if (this.dataList.length > 0) {
          this.orderDateils = result.data[0]
          this.getFindByOrderId()
          let that = this
          setTimeout(function () {
            that.onBtn()
          }, 500)
        }
      }
    },
    // 向iframe传值
    async onBtn() {
      let iframe = window.frames['addressData']
      let spBusInfo = session.getUserInfo()
      let res = await spAreaCodeToCity(spBusInfo.spAreaId)
      let spJson = {
        province: res.data.province,
        city: res.data.city,
        area: res.data.area,
        spAddress: spBusInfo.spAddress
      }
      let data = {
        start: spJson,
        end: this.orderDateils
      }
      iframe.onChangeStr(data)
    },
    // 查看详情
    openDateils(items, index) {
      this.orderDateils = items
      this.activeIndex = index
      this.getFindByOrderId()
      this.onBtn()
    },
    // 根据goodsId获取商品信息
    async getFindByOrderId() {
      let res = await findByGoodsId(this.orderDateils.goodsId)
      if (res.success) {
        this.goodsDateils = res.data
      }
    },
    // 立即抢单
    async openReceivingOrders() {
      this.$confirm('是否确认抢单?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消'
      })
        .then(async () => {
          let id = this.orderDateils.id
          let data = {}
          let res = await spReceivingOrders(id, data)
          if (res.success) {
            this.$message({
              message: res.message,
              type: 'success'
            })
            this.getOrdersList()
          } else {
            this.$message({
              message: res.message,
              type: 'warning'
            })
          }
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: '已取消抢单'
          })
        })
    }
  }
}
</script>
<style lang="less" scoped>
.active {
  border: #09bebe 2px solid;
}
.pageBox {
  width: 100%;
  height: calc(100vh - 60px);
  color: #202626;
  font-size: 14px;
  background: #f2f3f4;
  .pageTitBox {
    padding: 8px;
    .pageTitInner {
      background: #fff;
      padding: 16px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      span {
        padding-left: 40px;
      }
      .pageTit {
        font-size: 16px;
        font-weight: bold;
      }
    }
  }
  .pageList {
    margin-top: 8px;
    .grid-content {
      .pageListLeft {
        scrollbar-width: none;
        -ms-overflow-style: none;
        overflow-x: hidden;
        overflow-y: auto;
        height: calc(100vh - 138px);
      }
      .pageListLeft_items {
        display: flex;
        justify-content: space-between;
        align-items: center;
        background: #fff;
        padding: 16px;
        margin-bottom: 8px;
        .p_items_tit {
          font-size: 20px;
          font-weight: bold;
          cursor: pointer;
        }
        .p_items_tit:hover {
          font-size: 20px;
          font-weight: bold;
          color: #09bebe;
          cursor: pointer;
        }
        .p_items_dec {
          font-size: 14px;
          opacity: 0.7;
          padding: 8px 0;
        }
        .p_items_tag {
          font-size: 14px;
          span {
            padding-right: 24px;
            i {
              margin-right: 8px;
            }
          }
        }
        .p_items_right {
          width: 40px;
          text-align: center;
          .el-icon-right {
            font-size: 20px;
          }
        }
      }
      .pageListRight {
        scrollbar-width: none;
        -ms-overflow-style: none;
        overflow-x: hidden;
        overflow-y: auto;
        background: #fff;
        height: calc(100vh - 138px);
        .p_mapBox {
          padding: 8px;
          .p_mapInner {
            height: 320px;
            background: #f2f3f4;
          }
        }
        .p_orderBox {
          .p_orderTit {
            padding: 8px 16px;
            font-size: 24px;
            font-weight: bold;
          }
          .p_orderStatus {
            padding: 8px 16px;
            display: flex;
            align-items: center;
            margin: 16px 0;
            .p_o_tit {
              color: #fff;
              padding: 8px 16px;
              background: #204f4c;
              font-size: 14px;
              border-top-left-radius: 4px;
              border-bottom-left-radius: 4px;
            }
            .p_o_dec {
              color: #204f4c;
              padding: 8px 16px;
              background: #ffdd00;
              font-size: 14px;
              border-top-right-radius: 4px;
              border-bottom-right-radius: 4px;
            }
          }
          .p_userOrderBox {
            margin: 16px;
            padding: 16px;
            background: #e9fbf2;
            font-size: 16px;
            color: #078b8b;
            div {
              padding: 4px 0;
            }
          }
          .p_o_info {
            padding: 0 16px;
            margin: 16px 0;
            .p_o_i_tit {
              font-size: 16px;
              font-weight: bold;
              padding-top: 16px;
            }
            .p_o_info_items {
              padding: 8px 0;
              .p_o_info_items_tit {
                opacity: 0.7;
              }
            }
          }
          .p_order_paceBox {
            padding: 0 16px;
            margin-bottom: 70px;
            .p_order_paceTit {
              font-size: 16px;
              font-weight: bold;
            }
            .p_order_paceInner {
              padding: 16px 0;
              position: relative;
              .lin {
                position: absolute;
                background: #e4e8e8;
                width: 1px;
                height: 40px;
                left: 44px;
                bottom: 84px;
                z-index: 998;
              }
              .p_order_pace_items {
                padding: 24px 0;
                margin-left: 24px;
              }
              .purchased {
                display: flex;
                align-items: center;
                opacity: 0.5;
                .el-icon-success {
                  font-size: 40px;
                  opacity: 0.5;
                }
                .purchased_txtBox {
                  margin-left: 16px;
                  .purchased_txt {
                    font-size: 16px;
                  }
                  .purchased_time {
                    font-size: 12px;
                    padding-top: 4px;
                  }
                }
              }
              .pendingOrder {
                display: flex;
                align-items: center;
                .el-icon-warning {
                  font-size: 40px;
                  color: #ff9300;
                }
                .purchased_txtBox {
                  margin-left: 16px;
                  .purchased_txt {
                    font-size: 16px;
                  }
                  .purchased_time {
                    font-size: 12px;
                    padding-top: 4px;
                  }
                }
              }
            }
          }
        }
        .bottomBtnBox {
          position: absolute;
          bottom: 0;
          background: #fff;
          z-index: 999;
          border-top-color: #e4e8e8;
          border-top-width: 1px;
          border-top-style: solid;
          width: 41%;
        }
        .bottomBtnInner {
          display: flex;
          justify-content: space-between;
          align-items: center;
          padding: 16px;
        }
        .botLeft {
          display: flex;
          align-items: flex-end;
          .botLeft_tit {
            font-size: 14px;
            padding-bottom: 4px;
            line-height: 14px;
          }
          .botLeft_nunmber {
            font-size: 24px;
            font-weight: bold;
            color: #09bebe;
          }
        }
        .botRight_btn {
          background: #09bebe;
          border-radius: 4px;
          font-size: 16px;
          color: #fff;
          padding: 8px 60px;
        }
      }
    }
  }
}
</style>
