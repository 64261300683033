<template>
  <div class="pageContentBox">
    <div class="pageContent">
      <div>需要完善资料后才能解锁相应权限</div>
      <el-button @click="openShop">去完善资料</el-button>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {}
  },
  mounted() {},
  methods: {
    openShop() {
      this.$router.push({ path: '/shop' })
    }
  }
}
</script>
<style lang="less" scoped>
.pageContentBox {
  padding-top: 15%;
}
.pageContent {
  padding: 40px;
  background: #fff;
  margin: auto;
  width: 500px;
  border-radius: 4px;
}
</style>
