<template>
  <div>
    <div class="page_box" v-if="pageShow == true">
      <div class="pb_innerBox">
        <div class="pbi_titleBox">
          <div>收款账户管理</div>
          <el-button type="danger" @click="openClose">关闭</el-button>
        </div>
        <el-row :gutter="8" class="pbi_contentBox">
          <el-col :span="6" class="pbic_items">
            <div class="grid-content bg-purple addItems" @click="openAddAccount">
              <div class="add_title">
                <i class="el-icon-circle-plus" style="padding-right: 8px; font-size: 36px"></i
                ><span>点击添加收款账户</span>
              </div>
              <p>添加的账户可用于提现</p>
            </div>
          </el-col>
          <el-col :span="6" class="pbic_items" v-for="(items, index) in bankDataList" :key="index">
            <div class="grid-content bg-purple">
              <div class="pi_content">
                <div class="pic_img">
                  <img src="../../assets/img/alipay_logo.png" alt="" />
                </div>
                <div>
                  <div class="pic_title">支付宝账户</div>
                  <div class="pic_dec">
                    <div style="padding-right: 16px">{{ mobileStr(items.financeAccounts) }}</div>
                    <div>{{ userNameStr(items.financeName) }}</div>
                  </div>
                </div>
              </div>
              <div class="pic_btnBox">
                <div>
                  <span @click="editAccount(items)" style="cursor: pointer">编辑</span
                  ><span style="padding: 0 16px; opacity: 0.3">|</span
                  ><span @click="deleteAccount(items)" style="cursor: pointer">删除</span>
                </div>
              </div>
            </div>
          </el-col>
        </el-row>
      </div>
    </div>
    <!-- 添加/编辑账户 -->
    <addAccount
      :title="title"
      :addAccount="addAccountShow"
      @closeAddAccount="showAddAccount"
      :accountInfo="accountInfo"
      :editAccountInfo="editAccountInfo"
      :confirmAddAccountInfo="confirmAddAccountInfo1"
    ></addAccount>
  </div>
</template>
<script>
// 从session中获取token信息
import session from '@/utils/session.js'
import {
  findAllBySpBusIdPageAll,
  addBusFinancesAccount,
  deleteBusFinancesAccount,
  putBusFinancesAccount
} from '@/api/spBusFinance.js'
import AddAccount from '../../components/public/addAccount.vue'
export default {
  props: ['pageShow', 'bankAccountManageClose'],
  components: {
    AddAccount
  },
  data() {
    return {
      addAccountShow: false,
      title: null,
      accountInfo: null,
      pageShow: this.pageShow,
      bankDataList: [],
      query: {
        page: 1,
        pageSize: 20
      }
    }
  },
  mounted() {
    this.getSpBusIdPageAll()
  },
  methods: {
    // 关闭
    openClose() {
      this.pageShow = !this.pageShow
      this.bankAccountManageClose(this.pageShow)
    },
    // 分页获取收款账户
    async getSpBusIdPageAll() {
      let res = await findAllBySpBusIdPageAll(this.query)
      if (res.success) {
        this.bankDataList = res.data.rows
      } else {
        this.$message.error('获取失败！')
      }
    },
    // 添加账户
    openAddAccount() {
      this.title = '添加收款账户'
      this.addAccountShow = true
    },
    // 添加账户
    async confirmAddAccountInfo1(data) {
      let res = await addBusFinancesAccount(data)
      if (res.success) {
        this.getSpBusIdPageAll()
      } else {
        this.$message.error('操作失败，请稍后重试！')
      }
      this.addAccountShow = false
    },
    showAddAccount() {
      this.getSpBusIdPageAll()
      this.addAccountShow = false
    },
    // 编辑账户
    editAccount(items) {
      this.title = '编辑收款账户'
      this.accountInfo = items
      this.addAccountShow = true
    },
    async editAccountInfo(data) {
      let res = await putBusFinancesAccount(data)
      if (res.success) {
        this.$message({
          type: 'success',
          message: '编辑成功!'
        })
        this.addAccountShow = false
      } else {
        this.$message.error('操作失败，请稍后重试！')
      }
    },
    // 删除账户
    deleteAccount(items) {
      this.$confirm('此操作将删除收款账户, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消'
      })
        .then(async () => {
          let res = await deleteBusFinancesAccount(items.id)
          if (res.success) {
            this.getSpBusIdPageAll()
            this.$message({
              type: 'success',
              message: '删除成功!'
            })
          } else {
            this.$message.error('操作失败，请稍后重试！')
          }
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: '已取消删除'
          })
        })
    }
  }
}
</script>
<style lang="less" scoped>
.page_box {
  position: fixed;
  height: 100%;
  width: calc(100vw - 208px);
  background: #fff;
  z-index: 2003;
  .pb_innerBox {
    .pbi_titleBox {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 24px;
      border-bottom: 1px solid #f3f4f4;
      font-size: 20px;
    }
    .pbi_contentBox {
      padding: 24px;
      .pbic_items {
        margin-bottom: 8px;
      }
      .grid-content {
        height: 100px;
        border-radius: 16px;
        padding: 24px;
        background: #1677ff;
        position: relative;
        color: #fff;
        .pi_content {
          display: flex;
          align-items: center;
        }
        .pic_img {
          width: 32px;
          height: 32px;
          padding: 8px;
          background: #fff;
          border-radius: 44px;
          overflow: hidden;
          margin-right: 16px;
        }
        .pic_title {
          font-size: 16px;
          padding-bottom: 4px;
        }
        .pic_dec {
          display: flex;
          align-items: center;
          justify-content: space-between;
          opacity: 0.7;
        }
        .pic_btnBox {
          position: absolute;
          bottom: 24px;
          right: 24px;
        }
      }
      .addItems {
        background: #f5fafa;
        border: 1px dashed #09bebe;
        display: flex;
        text-align: center;
        flex-direction: column;
        justify-content: center;
        color: #202626;
        cursor: pointer;
        .add_title {
          font-size: 20px;
          color: #09bebe;
          display: flex;
          align-items: center;
          justify-content: center;
        }
        p {
          opacity: 0.7;
          margin: 4px 0;
        }
      }
    }
  }
}
</style>