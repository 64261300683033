<template>
  <el-container class="mainBox">
    <div class="title">欢迎使用菏康家政服务系统</div>
    <div class="from">
      <el-form ref="signInform" :model="signInform" :rules="signInRules">
        <div class="from_item">
          <!-- <div class="from_item_vlaue">域名</div>
        <div class="from_item_key">
          <el-input v-model="signInform.host" placeholder="请输入内容"></el-input>
          <span>&nbsp;&nbsp;.kwangda.com</span>
        </div> -->
        </div>
        <div class="from_item">
          <div class="from_item_vlaue">账号</div>
          <div class="from_item_key">
            <el-input v-model="signInform.mobile" placeholder="登录账号或负责人手机号"></el-input>
          </div>
        </div>
        <div class="from_item">
          <div class="from_item_vlaue">密码</div>
          <div class="from_item_key">
            <el-input v-model="signInform.password" type="password" placeholder="请输入登录密码"></el-input>
          </div>
        </div>
        <div class="from_item">
          <el-button type="primary" class="btn" @click="openLogin">登录</el-button>
        </div>
        <div class="from_item">
          <div class="switch">还没账号，<span class="register" @click="openRegister">去注册</span></div>
        </div>
      </el-form>
    </div>
  </el-container>
</template>
<script>
import { login, tokenParse } from '@/api/login.js'
import session from '@/utils/session'
export default {
  data() {
    return {
      signInform: {
        // host:'',
        mobile: '',
        password: ''
      },
      signInRules: {
        mobile: [{ required: true, trigger: 'blur', message: '账号不能为空' }],
        password: [{ required: true, trigger: 'blur', message: '密码不能为空' }]
      }
    }
  },
  methods: {
    async openLogin() {
      let result = await login(this.signInform)
      if (result.success) {
        const { data } = result
        session.setSession(data)
        this.parseToken(data)
        this.$message.success(result.message)
      } else if (result.code == 20001) {
        this.$message.error(result.message)
      }
    },
    //解析token
    async parseToken(token) {
      let result = await tokenParse(token)
      session.setData('userInfo', result.data)
      this.$router.push({ path: '/receivingOrders' })
    },
    openRegister() {
      this.$router.push({ path: '/register' })
    }
  }
}
</script>
<style lang="less" scoped>
.mainBox {
  margin: auto;
  width: 500px;
  display: block;
  padding-top: 10%;
}
.register {
  cursor: pointer;
}
.title {
  font-size: 40px;
  font-weight: 500;
}
.from {
  padding-top: 60px;
  .from_item {
    padding: 12px 0;
    .from_item_vlaue {
      padding-bottom: 16px;
    }
    .from_item_key {
      display: flex;
      align-items: center;
    }
    .btn {
      width: 100%;
    }
    .switch {
      display: flex;
      justify-content: center;
      span {
        color: #09bebe;
        font-weight: 500;
      }
    }
  }
}
</style>
