import request from '@/utils/request'

// 订单管理 - 根据服务商地区显示接单列表，默认根据服务商cityId显示订单
export function spBusInfoById(id) {
    return request({
        url: '/sp/busInfo/'+id,
        method: 'get',
    })
}
// 店铺 - 更新店铺基本信息
export function shopBaseInfoPut(data,id) {
    return request({
        url: '/sp/busInfo/shopBaseInfo/'+id,
        method: 'put',
        data:data
    })
}
// 店铺 - 更新服务信息
export function shopServiceInfoPut(data,id) {
    return request({
        url: '/sp/busInfo/serviceInfo/'+id,
        method: 'put',
        data:data
    })
}
// 店铺 - 更新账号信息
export function shopAccountInfoPut(data,id) {
    return request({
        url: '/sp/busInfo/accountInfo/'+id,
        method: 'put',
        data:data
    })
}
// 店铺 - 更新财务信息
export function shopFinanceInfoPut(data,id) {
    return request({
        url: '/sp/busInfo/financeInfo/'+id,
        method: 'put',
        data:data
    })
}
// 店铺 - 根据id查询
export function getByIdBusInfo(id) {
    return request({
        url: '/sp/busInfo/'+id,
        method: 'get',
    })
}

// 成员管理 - 根据服务商
export function busDepartmentByBusIdPageFindAll(data,id) {
    return request({
        url: '/sp/busDepartment/pageFindAll/' + id,
        method: 'get',
        params:data
    })
}
// 成员管理 - 新增成员
export function addBusDepartment(data) {
    return request({
        url: '/sp/busDepartment',
        method: 'post',
        data:data
    })
}
// 成员管理 - 更新成员信息
export function putBusDepartment(data) {
    return request({
        url: '/sp/busDepartment/'+ data.id,
        method: 'put',
        data:data
    })
}
// 成员管理 - 删除成员信息
export function deleteBusDepartment(id) {
    return request({
        url: '/sp/busDepartment/'+ id,
        method: 'delete',
    })
}