<template>
  <el-container class="mainBox">
    <div class="title">欢迎使用菏康家政服务系统</div>
    <el-form :model="ruleForm" ref="ruleForm" label-width="100px" class="demo-ruleForm">
      <div class="from_item">
        <div class="from_item_vlaue">负责人姓名</div>
        <div class="from_item_key">
          <el-input v-model="ruleForm.contactPerson" placeholder="请输入负责人姓名"></el-input>
        </div>
      </div>
      <div class="from_item">
        <div class="from_item_vlaue">负责人手机号</div>
        <div class="from_item_key">
          <el-input v-model="ruleForm.contactPhone" placeholder="请输入负责人手机号"></el-input>
        </div>
      </div>
      <div class="from_item">
        <div class="from_item_vlaue">验证码</div>
        <div class="from_item_key">
          <el-input v-model="code" placeholder="请输入短信验证码"></el-input>
          <el-button v-if="this.btnShow == true" style="width: 100%" @click="openCode">发送短信</el-button>
          <el-button v-if="this.btnShow == false" style="width: 100%" disabled @click="openCode">已发送</el-button>
        </div>
      </div>
      <div class="from_item">
        <div class="from_item_vlaue">账号密码</div>
        <div class="from_item_key">
          <el-input v-model="ruleForm.accountPassword" placeholder="请输入密码" type="password"></el-input>
        </div>
      </div>
      <div class="from_item">
        <div class="from_item_vlaue">确认密码</div>
        <div class="from_item_key">
          <el-input v-model="confirmPassword" placeholder="请再次输入密码" type="password"></el-input>
        </div>
      </div>
    </el-form>
    <el-col :span="24">
      <div class="from_item">
        <el-checkbox v-model="checked" name="type"></el-checkbox>
        <span style="padding-left: 12px"
          >已阅读理解并同意《<span class="openAgreeBtn" @click="openAgree">菏康商家服务协议</span>》</span
        >
      </div>
      <div class="from_item">
        <el-button type="primary" style="width: 100%" @click="acRegister">注册</el-button>
      </div>
      <div class="from_item">
        <div class="switch">已有账号，<span class="lgoin" @click="openLogin">去登录</span></div>
      </div>
    </el-col>
    <agreeDialogVue
      :agreeDialog="agreeDialogShow"
      @closeAgreeDialog="showAgreeDialog"
      :confirmAgreeInfo="confirmAgreeInfo1"
    ></agreeDialogVue>
  </el-container>
</template>
<script>
import { register, validateCode } from '@/api/login.js'
import AgreeDialogVue from '../../components/public/agreeDialog.vue'

export default {
  components: {
    agreeDialogVue: AgreeDialogVue
  },
  data() {
    return {
      agreeDialogShow: false,
      checked: false,
      ruleForm: {
        contactPerson: '',
        contactPhone: '',
        accountPassword: ''
      },
      code: '',
      sysCode: '',
      confirmPassword: '',
      btnShow: true
    }
  },
  methods: {
    openAgree() {
      this.agreeDialogShow = true
    },
    confirmAgreeInfo1() {
      this.agreeDialogShow = false
      this.checked = true
    },
    showAgreeDialog() {
      this.agreeDialogShow = false
    },
    openLogin() {
      this.$router.push({ path: '/login' })
    },
    async openCode() {
      if (this.ruleForm.contactPhone == '') {
        this.$message.error('请输入手机号')
      } else {
        let res = await validateCode(this.ruleForm.contactPhone)
        if (res.success) {
          this.$message({
            message: '验证码已发送',
            type: 'success'
          })
          this.btnShow = false
          this.sysCode = res.data
        } else {
          this.$message.error(res.message)
        }
      }
    },
    async acRegister() {
      if (this.checked) {
        if (this.ruleForm.accountPassword == this.confirmPassword) {
          if (this.code == this.sysCode) {
            let res = await register(this.ruleForm)
            if (res.success) {
              this.$message({
                message: '注册成功！',
                type: 'success'
              })
              this.$router.push({ path: '/login' })
            }
          } else {
            this.$message.error('短信验证码输入错误！')
          }
        } else {
          this.$message.error('两次密码输入不一致！')
        }
      } else {
        this.$message.error('请确认是否同意协议')
      }
    }
  }
}
</script>
<style lang="less" scoped>
.mainBox {
  margin: auto;
  width: 500px;
  display: block;
  padding-top: 8%;
}
.title {
  font-size: 40px;
  font-weight: 500;
}
.btn {
  width: 100%;
  margin-top: 24px;
}
.lgoin {
  cursor: pointer;
}
.switch {
  padding-top: 24px;
  display: flex;
  justify-content: center;
  span {
    color: #09bebe;
    font-weight: 500;
  }
}
.demo-ruleForm {
  padding-top: 60px;
}
.from_item {
  padding: 12px 0;
  .from_item_vlaue {
    padding-bottom: 16px;
  }
  .from_item_key {
    display: flex;
    align-items: center;
  }
  .btn {
    width: 100%;
  }
  .switch {
    display: flex;
    justify-content: center;
    span {
      color: #09bebe;
      font-weight: 500;
    }
  }
}
.openAgreeBtn {
  cursor: pointer;
}
.openAgreeBtn:hover {
  color: #09bebe;
  text-decoration: underline;
}
</style>
