import request from '@/utils/request'

// 财务管理 - 财务概况
export function spBusFinanceInfo() {
    return request({
        url: '/sp/busFinanceInfo/spBusId',
        method: 'get',
    })
}
// 财务管理 - 订单概况
export function spBusDataInfo() {
    return request({
        url: '/sp/busDataInfo/ordersData',
        method: 'get',
    })
}
// 财务管理 - 订单概况
export function spBusBillInfo(data) {
    return request({
        url: '/sp/busBillInfo/spBusId',
        method: 'post',
        data: data
    })
}

// 店铺 - 抽成比例
export function findSetType(setType) {
    return request({
        url: '/sp/set/setType/' + setType,
        method: 'get',
    })
}

// 财务数据
export function findSpBusDate() {
    return request({
        url: '/sp/busFinancesInfo/spBusDate',
        method: 'get',
    })
}
// 财务数据
export function findByNewInfo() {
    return request({
        url: '/sp/busBillsInfo/findByNewInfo',
        method: 'get',
    })
}
// 商家接单财务明细
export function findBySpBusIdPageAll(data) {
    return request({
        url: '/sp/busFinancesInfo/findBySpBusIdPageAll',
        method: 'get',
        params: data
    })
}
// 服务商管理 - 服务商资金流水财务明细息
export function findBySpBusBillsPageAll(data) {
    return request({
        url: '/sp/busBillsInfo/spBusIdPageAll',
        method: 'get',
        params: data
    })
}
// 商家提现
export function sysToBusPay(data) {
    return request({
        url: '/sysPay/sysToBusPay',
        method: 'post',
        data: data
    })
}
// 当月提现记录
export function spBusIdToTypePageAll(type, data) {
    return request({
        url: '/sp/busBillsInfo/spBusIdToTypePageAll/' + type,
        method: 'get',
        params: data
    })
}
// 分页获取商家收款账户
export function findAllBySpBusIdPageAll(data) {
    return request({
        url: '/sp/busFinancesAccount/findAllBySpBusIdPageAll',
        method: 'get',
        params: data
    })
}
// 添加收款账户
export function addBusFinancesAccount(data) {
    return request({
        url: '/sp/busFinancesAccount',
        method: 'post',
        data: data
    })
}
// 编辑收款账户
export function putBusFinancesAccount(data) {
    return request({
        url: '/sp/busFinancesAccount/' + data.id,
        method: 'put',
        data: data
    })
}
// 删除收款账户
export function deleteBusFinancesAccount(id) {
    return request({
        url: '/sp/busFinancesAccount/' + id,
        method: 'delete',
    })
}