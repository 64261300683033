<template>
  <div class="pageContentBox">
    <div class="pageContent">
      <div style="text-align: center">当前没有内容!</div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {}
  },
  mounted() {},
  methods: {}
}
</script>
<style lang="less" scoped>
.pageContentBox {
  padding-top: 15%;
}
.pageContent {
  padding: 40px;
  background: #fff;
  margin: auto;
  width: 500px;
  border-radius: 4px;
}
</style>
