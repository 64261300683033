<template>
  <el-dialog
    title="菏康家政商家服务协议"
    :visible.sync="agreeDialog"
    @close="close"
    width="50%"
    :before-close="handleClose"
    :close-on-click-modal="false"
  >
    <div style="padding: 0 40px">
      <div style="text-align: center; font-size: 20px; font-weight: bold">菏康家政商家服务协议</div>
      <div>
        <p>
          <font size="2"
            ><b
              >特别提醒：您作为商户（以下简称“甲方”）在确认签署本合同之前，请仔细阅读本合同所有条款（特别是部分加粗的条款）。一旦甲方确认本合同后，即视为甲方已充分理解本合同所有条款的含义及相应的法律后果，同意与菏泽菏康养老服务有限公司（以下简称“乙方”），以数据电文形式订立本协议并接受本协议的约束。</b
            ></font
          >
        </p>
        <p>
          <font size="2"><br /></font>
        </p>
        <p>
          <font size="2"
            >为了更好的为商家服务以及保障消费者权益，甲、乙双方本着平等合作、互惠互利的原则，在符合相关法律法规，不违反市场竞争的情况下，甲方须遵循乙方的运营管理规范，甲、乙双方经友好协商达成如本协议：</font
          >
        </p>
        <p>
          <font size="2"><br /></font>
        </p>
        <p>
          <font size="2"><b>一、定义</b></font>
        </p>
        <p><font size="2">除非另有明确约定，否则，下列词语在本协议中具有如下含义：</font></p>
        <p>
          <font size="2"><b>菏康：</b>指由乙方控制的或承认的提供各项服务的主体；</font>
        </p>
        <p>
          <font size="2"><b>平台：</b>指乙方用于向甲方提供综合性服务的各项软件服务系统的统称；</font>
        </p>
        <p>
          <font size="2"><b>消费者：</b>指通过乙方或乙方相关联方服务与甲方进行商品或服务交易的主体；</font>
        </p>
        <p>
          <font size="2"
            ><b>技术服务费：</b
            >指甲方因使用乙方提供的平台服务，而需向乙方支付的服务费用。具体收费方式详见协议约定；</font
          >
        </p>
        <p>
          <font size="2"><br /></font>
        </p>
        <p>
          <font size="2"><b>二、协议范围</b></font>
        </p>
        <p>
          <font size="2"><b>2.1、签约主体</b></font>
        </p>
        <p>
          <font size="2"
            >为向甲方或消费者提供更加优质的服务，乙方有权不断调整平台服务及其具体服务，服务系统及其具体服务名称、功能、域名的调整，不影响本协议的效力，具体为：</font
          >
        </p>
        <p>
          <font size="2"
            >2.1.1
            乙方有权将提供的服务内容转让给第三方，且乙方将以本协议约定的方式通知甲方，通知后如甲方继续使用服务的，视为甲方同意本协议项下服务的提供者变更。</font
          >
        </p>
        <p>
          <font size="2"
            >2.1.2
            本协议项下服务的提供者还有可能因为服务内容调整而新增，乙方将以本协议约定的方式通知甲方，通知后如甲方继续使用服务的，视为甲方同意平台服务的提供者变更。</font
          >
        </p>
        <p>
          <font size="2"><b>2.2、完整协议及协议变更</b></font>
        </p>
        <p>
          <font size="2"
            >由于互联网高速发展，甲乙双方签署的本协议列明的条款并不能完整罗列并覆盖双方之间的所有权利与义务，现有的约定也不能保证完全符合未来发展的需求。因此，乙方可根据自身运营安排或法律法规要求等随时调整、修订本协议，并通过本协议约定的通知途径发布公告，修改自公告载明的生效之日生效。甲方在前述修改生效后继续使用乙方相关服务，则表示接受修改；若甲方不同意修改后的内容，甲方有权停止使用相关服务且应及时通知乙方，甲方须自行负责将业务数据迁出，乙方将及时完成服务费结算。</font
          >
        </p>
        <p><font size="2">双方协商一致的，也可另行变更相关服务和对应协议内容。</font></p>
        <p>
          <font size="2"><br /></font>
        </p>
        <p>
          <font size="2"><b>三、合作内容</b></font>
        </p>
        <p>
          <font size="2"><b>3.1、服务内容</b></font>
        </p>
        <p>
          <font size="2">3.1.1 甲方提供该公司可服务项目详细明细，由乙方上传至乙方平台并负责平台运营相关工作；</font>
        </p>
        <p>
          <font size="2">3.1.2 甲方通过乙方提供的包括但不限于APP、WEB平台等菏康旗下产品承接该公司可服务项目订单；</font>
        </p>
        <p>
          <font size="2"><b>3.2、双方权利与义务</b></font>
        </p>
        <p>
          <font size="2"
            >3.2.1
            甲方保证向乙方提交的信息真实、完整、合法、准确、有效。为维护平台良好运行秩序及维护甲方权益，乙方有权对甲方提交的信息进行核实，甲方应当配合。如甲方信息变动应及时更新；</font
          >
        </p>
        <p>
          <font size="2"
            >3.2.2
            甲方应保证具备履行提供服务活完成服务所需的相关资质和能力，应妥善保管消费者的财务，在提供服务活完成服务过程中尽到应尽的注意义务；</font
          >
        </p>
        <p><font size="2">3.2.3 乙方为甲方提供包括但不限于用于承接订单的APP、WEB平台等菏康旗下产品；</font></p>
        <p>
          <font size="2"
            >3.2.4
            服务完成后应通过菏康家政平台上传服务图片，并告知消费者结束服务；对于服务补差应提前与消费者协商，服务完成后通过菏康家政平台填写补差原因与补差金额；</font
          >
        </p>
        <p>
          <font size="2"
            >3.2.5
            甲方应严格按照乙方制定的超时规则，应及时有效的进行接单、服务等操作，甲方接单后如遇不可控因素无法完成服务时，应及时通知消费者。如因甲方原因造成消费者投诉或申请售后，包括但不限于超时、服务不好、损坏财物等因素，乙方有权按照本协议《服务处罚表》对甲方做出相应处罚；</font
          >
        </p>
        <p>
          <font size="2"
            >3.2.6
            甲方应当按照消费者需求向消费者开具全额服务票据（包括但不限于发票），且该服务票据应当符合国家有关规定或商业惯例；</font
          >
        </p>
        <p>
          <font size="2"
            >3.2.7
            如消费者在使用甲方提供的服务过程中遭受任何人身伤害或财产损失的，甲方应按照法律法规承担相应责任。如在服务过程中因甲方原因而使消费者的合法权益收到损害，并且消费者直接向乙方提出赔偿要求的，经核实情况后乙方有权根据实际情况拍断对消费者进行先行赔偿，再向甲方追偿。乙方行使上述行为并不意味着乙方取代甲方成为服务提供方，或取代甲方承担根据法律法规的规定作为服务提供方应承担的任何或所有义务。即便本协议终止，我们仍有权更具本协议的规定处理因本协议有效期内发生的交易所导致的消费者的索赔；</font
          >
        </p>
        <p>
          <font size="2"
            >3.2.8 如乙方提供的服务订单有任何问题与纠纷，甲方有权随时告知乙方针对问题与纠纷进行合理解决；</font
          >
        </p>
        <p>
          <font size="2"
            >3.2.9
            甲方一旦注册成功，成为本站的合法用户，将得到一个密码喝用户名。您可随时根据只是改变您的密码。甲方将对用户名和密码安全负全部责任。另外，每个用户都要对以其用户名进行的所有活动和时间负全部责任。甲方若发现任何非法使用用户账户或存在安全漏洞的情况，请立即告知乙方；</font
          >
        </p>
        <p>
          <font size="2"
            >3.2.10
            乙方不对甲方所提供的服务质量问题负责。乙方有判定甲方的行为是否符合乙方服务条款的要求和精神的保留权利，如果甲方违背了服务条款的规定，乙方有权终端对其提供一切服务的权利，包括但不限于网络、线下支持等；</font
          >
        </p>
        <p>
          <font size="2"
            >3.2.11
            甲方应严格遵守本协议及乙方相关运营管理规定，如有违反，甲方应承担违约责任，并赔偿乙方由此遭受的全部损失；</font
          >
        </p>
        <p>
          <font size="2"
            >3.2.12
            如甲方再使用乙方提供的包括APP、WEB平台等菏康旗下的产品服务时，还购买或使用了第三方提供的产品，包括但不限于保险、社交服务、支付服务等，相关权利和义务应按照甲方与其他产品或服务提供方之间的协议约定履行；</font
          >
        </p>
        <p>
          <font size="2"
            >3.2.13
            甲方同意乙方使用其提供的有关店铺相关资料，包括但不限于店铺名称、地址、服务项目、服务范围、电话等信息，此信息可用在消费者订单详情中展示，以便消费者直观辨别；</font
          >
        </p>
        <p>
          <font size="2"
            >3.2.14
            乙方应按照本协议约定向甲方提供包括但不限于APP、WEB平台等相关技术支持，如甲方在使用股过程中遇到问题，乙方应尽全力配合协助解决；</font
          >
        </p>
        <p>
          <font size="2"
            >3.2.15
            乙方有权依据消费者投诉或申请售后的订单或问题进行核实，如因甲方原因，乙方有权按照《服务处罚表》对甲方做出相应处罚；</font
          >
        </p>
        <p>
          <font size="2"
            ><b
              >乙方提醒：甲方做为服务者，在提供服务活完成服务的过程中或消费者接受服务的过程中，请时刻注意您的人身财产安全。</b
            ></font
          >
        </p>
        <p>
          <font size="2"><br /></font>
        </p>
        <p>
          <font size="2"><b>四、技术服务费的收取</b></font>
        </p>
        <p>
          <font size="2"
            >4.1
            消费者通过乙方提供的平台进行下单，甲方通过乙方提供的平台承担该公司可服务项目至成功完成该项服务，并在用户确认服务后，乙方按照被协议附件《服务费费率表》所列标准，向甲方收取技术服务费，剩余部分将转入甲方在乙方的财务账户中；</font
          >
        </p>
        <p>
          <font size="2"
            >4.2
            乙方有权根据风险评估结果、商业运营策略适时调整技术服务费定价及规则，但应与调整前按照本协议约定的通知方式通知甲方。若调整生效后，甲方仍然继续使用本协议项下服务的，即视为甲方同意乙方对上述定价及规则的调整。若甲方不同意上述调整，则可在调整生效前关闭；</font
          >
        </p>
        <p>
          <font size="2"
            >4.3
            甲方知晓，本协议项下服务有赖于乙方系统的准确运行及操作。若出现系统差错、故障，造成甲方不当获利等情形的，甲方同意乙方可以自行采取更正差错、扣划款项、暂停服务等适当纠正措施。</font
          >
        </p>
        <p>
          <font size="2"><br /></font>
        </p>
        <p>
          <font size="2"><b>五、责任限制</b></font>
        </p>
        <p>
          <font size="2"
            >5.1
            乙方将按照现有技术和条件所能达到的现状态向甲方通过平台提供综合性商家服务。乙方依法律规定承担基础保障义务，但无法对由于信息网络设备维护、连接故障，电脑、通讯或其他系统的故障，黑客攻击、计算机病毒侵入或发作，电力故障，罢工，暴乱，火灾，洪水，风暴，爆炸，战争，政府行为，司法行政机关的命令，因第三方原因或因法律法规、政策调整或政府管制而造成的暂时性关闭、服务调整等而给甲方造成的损害结果承担责任。</font
          >
        </p>
        <p>
          <font size="2"
            >因受不可抗力影响而不能履行或不能完全履行本协议的一方无需承担违约责任。但是乙方会尽可能合理地协助处理善后事宜，并努力是客户免受经济损失。双方按照事件对协议的履行的影响程度，再行协商决定是否继续履行本协议或终止协议。</font
          >
        </p>
        <p><font size="2">5.2 甲方了解并同意，下列情形下一番搞不承担任何责任：</font></p>
        <p><font size="2">（a）并非由于乙方的故意或过失而导致平台未能正常运行或发生故障；</font></p>
        <p><font size="2">（b）由于第三方的故意或过失导致用户及/或任何第三方遭受损失的；</font></p>
        <p>
          <font size="2"
            >（c）甲方违反本协议或其他平台服务规则，或违反与乙方或乙方的关联公司的其它协议、协议及/或约定、规范和政策，被相应处罚的。</font
          >
        </p>
        <p>
          <font size="2"><br /></font>
        </p>
        <p>
          <font size="2"><b>六、违约及赔偿责任</b></font>
        </p>
        <p>
          <font size="2"
            >6.1
            任何一方违反本协议的约定，视为该方违约，应当依照相应条款承担违约责任，如违约方给其他方造成损失的，依法或依本协议的约定承担相应的赔偿责任。</font
          >
        </p>
        <p>
          <font size="2"
            >6.2
            如甲方的行为使乙方及/或乙方的关联公司遭受损失（包括自身的直接经济损失、商誉损失及对外支付的赔偿金、和解款、律师费、诉讼费等间接经济损失），甲方应赔偿乙方及/或乙方的关联公司的上述全部损失。</font
          >
        </p>
        <p>
          <font size="2"
            >如乙方认定甲方的行为已经或者将必然导致第三方遭受损失，甲方授权乙方自甲方的菏康账户中划扣相应款项进行支付。</font
          >
        </p>
        <p>
          <font size="2"><br /></font>
        </p>
        <p>
          <font size="2"><b>七、通知</b></font>
        </p>
        <p><font size="2">7.1 甲方同意乙方可以以以下任一合理的方式送达各类通知：</font></p>
        <p><font size="2">（a） 公示的文案；</font></p>
        <p><font size="2">（b） 站内信、页面提示、客户端推送消息；</font></p>
        <p><font size="2">（c） 根据甲方预留于服务系统里的联系方式发出的电子邮件、短信、函件等。</font></p>
        <p>
          <font size="2"
            >7.2
            若由于甲方提供的电子邮箱、手机号码、通讯地址等信息错误或未及时在服务系统中更新，导致甲方未收到相关规则、通知、提示等信息的，一切后果及责任由甲方自行承担。</font
          >
        </p>
        <p>
          <font size="2"><br /></font>
        </p>
        <p>
          <font size="2"><b>八、保密</b></font>
        </p>
        <p>
          <font size="2"
            >8.1
            双方为了本协议之目的，已经或将会提供或披露某些保密信息。保密信息指由协议一方持有的与其业务、经营、技术及权利等事项相关的，非公开的信息（包括但不限于用户投诉处理方案、违规处理方案、诉讼情况等）、资讯、数据、资料。其中，披露信息的一方为“披露方”，而接受信息的一方为“接收方”。</font
          >
        </p>
        <p>
          <font size="2"
            >8.2
            除本协议另有规定的情况外，未经披露方事先书面同意，接收方不可为其自身业务目的或其他目的使用或向任何第三方披露任何披露方的保密信息。双方应保证本方员工、关联方及其员工或专业顾问履行上述义务。</font
          >
        </p>
        <p><font size="2">8.3 保密信息不包含下列任何信息：</font></p>
        <p><font size="2">（1）在披露时已经被公众所知的信息；</font></p>
        <p><font size="2">（2）在披露后，非因可归责于接收方的原因被公众所知的信息；</font></p>
        <p><font size="2">（3）接收方不承担保密义务而从拥有正当权限的第三方合法获得的信息；</font></p>
        <p><font size="2">（4）由接收方在没有参考披露方保密信息的情况下独立开发的信息；</font></p>
        <p>
          <font size="2"
            >（5）由披露方以书面形式同意接收方可以进一步披露的信息，但仅限于披露方书面同意中规定的范畴。</font
          >
        </p>
        <p><font size="2">8.4 接收方不会因为在遵守本条的规定下进行的披露而承担任何法律责任：</font></p>
        <p><font size="2">（1）一方为实现本协议之目的，需向其员工、关联方及其员工或专业顾问等进行的信息披露。</font></p>
        <p>
          <font size="2"
            >（2）因甲方使用的服务的规则（包括但不限于在线支付等功能）或该服务本身依据法律法规等的要求，需要向服务提供方提供相关主体必要信息的。</font
          >
        </p>
        <p>
          <font size="2"
            >（3）根据法律法规、司法机关、行政机关或证券交易所的规定、指令或要求，接收方必须披露任何保密信息的，但是，接收方应在可行并法律允许的条件下及时向披露方以本协议约定方式发出通知，并且应尽合理努力协助披露方寻求救济措施。如披露方未能获取救济措施，则接收方应尽合理努力仅提供被要求披露的那一部分保密信息。</font
          >
        </p>
        <p><font size="2">8.5 保密义务不因本协议的解除、终止或撤销而失效。</font></p>
        <p>
          <font size="2"><br /></font>
        </p>
        <p>
          <font size="2"><b>九、法律适用及争议解决</b></font>
        </p>
        <p>
          <font size="2"
            >9.1
            本协议之订立、生效、解释、修订、补充、终止、执行与争议解决均适用中华人民共和国大陆地区（仅为本协议之目的，不包括香港和澳门特别行政区和台湾）法律。</font
          >
        </p>
        <p><font size="2">9.2 本协议签订地为中华人民共和国山东省菏泽市开发区。</font></p>
        <p>
          <font size="2"
            >9.3 本协议所产生的任何争议，如协调不成时，任何一方均可向协议签订地有管辖权的人民法院提起诉讼。</font
          >
        </p>
        <p>
          <font size="2"><br /></font>
        </p>
        <p>
          <font size="2"><b>十、其它</b></font>
        </p>
        <p>
          <font size="2"
            >10.1
            本协议自双方加盖公章或协议专用章之日（以后盖章者为准）起生效。如甲方通过网络线上点击确认勾选的方式来确认本协议的，则本协议自点击确认之时起生效，且该点击确认行为与甲方加盖章行为具有相同法律效力，若两种行为同时存在，以先发生的行为时间作为协议生效时间。本协议自生效之日起，有效期壹年，到期前和后续历次有效期届满前，除非甲乙双方任意一方以本协议约定的通知方式告知对方不再续签，否则本协议将自动续签一年，续签次数不限。</font
          >
        </p>
        <p>
          <font size="2"
            >10.2 除本协议另有约定外，在双方均无争议的前提下，一方可提前10个工作日通知另一方而终止本协议。</font
          >
        </p>
        <p>
          <font size="2"
            >10.3
            如遇国家法律、法规或政策变化，致使本协议的全部或部分条款不再符合国家法律、法规或政策的要求，由此产生的影响不视为乙方违约。</font
          >
        </p>
        <p>
          <font size="2"
            >10.4
            本协议任何条款的无效或不可执行，不影响其他条款的有效性和可执行性，也不影响整个协议的效力。本协议的附件包括：《服务费费率表》、《服务处罚表》。</font
          >
        </p>
        <p>
          <font size="2"
            >10.5 本协议的终止或解除，不影响缔约方要求赔偿损失的权利以及本协议中有关争议解决条款的效力。</font
          >
        </p>
        <p>
          <font size="2"><br /></font>
        </p>
        <p>
          <font size="2"><br /></font>
        </p>
        <p>
          <font size="2"><b>附件一：服务费费率表</b></font>
        </p>
        <p><font size="2">收费模式：单笔收费；费率：12%；</font></p>
        <p>
          <font size="2">交易金额：单次服务用户实付金额 = 订单实付金额 + 交易补差金额 - 售后补贴金额 - 退款金额</font>
        </p>
        <table>
          <tbody>
            <tr>
              <td>
                <p><font size="2">交易金额(元)</font></p>
              </td>
              <td>
                <p><font size="2">费率</font></p>
              </td>
              <td>
                <p><font size="2">应付服务费(元)</font></p>
              </td>
              <td>
                <p><font size="2">实付服务费(元)</font></p>
              </td>
              <td>
                <p><font size="2">到账金额(元)</font></p>
              </td>
            </tr>
            <tr>
              <td>
                <p><font size="2">167.9</font></p>
              </td>
              <td>
                <p><font size="2">12%</font></p>
              </td>
              <td>
                <p><font size="2">20.148</font></p>
              </td>
              <td>
                <p><font size="2">20.15</font></p>
              </td>
              <td>
                <p><font size="2">147.75</font></p>
              </td>
            </tr>
            <tr>
              <td>
                <p><font size="2">167.7</font></p>
              </td>
              <td>
                <p><font size="2">12%</font></p>
              </td>
              <td>
                <p><font size="2">20.124</font></p>
              </td>
              <td>
                <p><font size="2">20.12</font></p>
              </td>
              <td>
                <p><font size="2">147.58</font></p>
              </td>
            </tr>
          </tbody>
        </table>
        <p>
          <font size="2"><br /></font>
        </p>
        <p>
          <font size="2"><b>附件二、服务处罚表</b></font>
        </p>
        <p></p>
        <table>
          <tbody>
            <tr>
              <td>
                <p><font size="2">名称</font></p>
              </td>
              <td>
                <p><font size="2">处罚方式</font></p>
              </td>
            </tr>
            <tr>
              <td>
                <p><font size="2">服务不达标，造成消费者申请售后/投诉</font></p>
              </td>
              <td>
                <p><font size="2">免费重做，并扣除订单金额最高50%费用</font></p>
              </td>
            </tr>
            <tr>
              <td>
                <p><font size="2">因甲方操作不当造成消费者人身财产损失</font></p>
              </td>
              <td>
                <p><font size="2">甲方承担全部赔偿，并扣除订单金额100%费用</font></p>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
    <span slot="footer" class="dialog-footer">
      <el-button @click="agreeDialog = false">取 消</el-button>
      <el-button type="primary" @click="userConfirmAgreeInfo()">确 定</el-button>
    </span>
  </el-dialog>
</template>
<script>
export default {
  props: ['agreeDialog', 'confirmAgreeInfo'],
  data() {
    return {
      agreeDialog: false,
      agreeDialogShow: this.agreeDialog
    }
  },
  methods: {
    close() {
      this.$emit('closeAgreeDialog')
    },
    handleClose() {
      this.agreeDialog = false
    },
    userConfirmAgreeInfo() {
      this.confirmAgreeInfo()
    }
  }
}
</script>