<template>
  <el-container>
    <el-dialog title="服务详情" :visible.sync="orderDateils" width="60%" @close="close" append-to-body>
      <div class="itemsTitBox">
        <span class="items_tit">
          服务人员：
          {{ JSON.parse(orderDateil.spServiceInfo)?.spServiceUser?.name }}
        </span>
        <span>
          联系电话：
          {{ JSON.parse(orderDateil.spServiceInfo)?.spServiceUser?.mobile }}
        </span>
      </div>
      <div class="contentItemsBox" v-if="JSON.parse(orderDateil.spServiceInfo)?.start">
        <div class="items_tit">服务开始时间：{{ JSON.parse(orderDateil.spServiceInfo).start?.startTime }}</div>
        <div class="items_tit">服务照片：</div>
        <div class="serviceStartImgBox">
          <div
            v-for="(items, index) in JSON.parse(orderDateil.spServiceInfo).start?.imgList"
            :key="index"
            style="margin-right: 8px"
          >
            <el-image style="width: 100px; height: 100px" :src="items.imageUrl" fit="fill"></el-image>
          </div>
        </div>
      </div>
      <div class="contentItemsBox" v-if="JSON.parse(orderDateil.spServiceInfo)?.end">
        <div class="items_tit">服务结束时间：{{ JSON.parse(orderDateil.spServiceInfo).end?.endTime }}</div>
        <div class="items_tit">服务照片：</div>
        <div class="serviceStartImgBox">
          <div
            v-for="(items, index) in JSON.parse(orderDateil.spServiceInfo).end?.imgList"
            :key="index"
            style="margin-right: 8px"
          >
            <el-image style="width: 100px; height: 100px" :src="items.imageUrl" fit="fill"></el-image>
          </div>
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="closeOrderDateils">关 闭</el-button>
      </span>
    </el-dialog>
  </el-container>
</template>
<script>
// 从session中获取token信息
import session from '@/utils/session.js'
export default {
  props: ['orderDateils', 'orderDateilsInfo'],
  data() {
    return {
      orderDateil: {},
      startList: []
    }
  },
  watch: {
    orderDateilsInfo(n) {
      this.orderDateil = n
    }
  },
  mounted() {},
  methods: {
    // 关闭弹框
    closeOrderDateils() {
      this.orderDateils = false
    },
    close() {
      this.$emit('closeOrderDateils')
    }
  }
}
</script>
<style lang="less" scoped>
.el-select {
  width: 100%;
}
.input_item {
  padding: 8px 0;
  .item_vlaue {
    padding-bottom: 8px;
  }
  .select {
    width: 100%;
  }
}
.el-cascader {
  width: 100%;
}
.depositBox {
  margin-top: 8px;
  .deposit_content {
    padding: 16px;
    background: #09bebe;
    border-radius: 4px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: #fff;
    .d_l_tit {
      font-size: 18px;
      font-weight: bold;
    }
    .d_l_dec {
      font-size: 14px;
    }
  }
  p {
    font-size: 12px;
  }
}
.serviceStartImgBox {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}
.itemsTitBox {
  padding-bottom: 24px;
  .items_tit {
    padding-right: 40px;
  }
}
.contentItemsBox {
  background: #f2f3f4;
  margin-bottom: 8px;
  padding: 16px;
  .items_tit {
    padding-bottom: 16px;
  }
}
</style>
