<template>
  <el-dialog
    :title="this.title"
    :visible.sync="addAccount"
    @close="close"
    width="30%"
    :before-close="handleClose"
    :close-on-click-modal="false"
  >
    <div class="formData">
      <el-form label-width="80px" :model="formData">
        <el-form-item label="账户类型">
          <el-select v-model="formData.accountType" placeholder="请选择">
            <el-option v-for="item in accountTypeList" :key="item.value" :label="item.value" :value="item.value">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="账号类型">
          <el-select v-model="formData.loginType" placeholder="请选择">
            <el-option v-for="item in loginTypeList" :key="item.value" :label="item.value" :value="item.label">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="收款账户">
          <el-input v-model="formData.financeAccounts"></el-input>
        </el-form-item>
        <el-form-item label="真实姓名">
          <el-input v-model="formData.financeName"></el-input>
        </el-form-item>
      </el-form>
    </div>
    <span slot="footer" class="dialog-footer">
      <el-button @click="openClose">取 消</el-button>
      <el-button type="primary" @click="userConfirmAddAccountInfo()">确 定</el-button>
    </span>
  </el-dialog>
</template>
<script>
// 从session中获取token信息
import session from '@/utils/session.js'
export default {
  props: ['addAccount', 'title', 'accountInfo', 'confirmAddAccountInfo', 'editAccountInfo'],
  data() {
    return {
      formData: {
        spBusId: '',
        accountType: '',
        loginType: '',
        financeAccounts: '',
        financeName: ''
      },
      accountTypeList: [
        {
          label: 'aliPay',
          value: '支付宝'
        }
      ],
      loginTypeList: [
        {
          label: 'logon',
          value: '登录账号'
        },
        {
          label: 'user',
          value: '用户UID'
        }
      ]
    }
  },
  watch: {
    accountInfo() {
      this.formData = this.accountInfo
    },
    title() {
      let spBusInfo = session.getUserInfo()
      if (this.title == '添加收款账户') {
        this.formData = {
          spBusId: spBusInfo.id,
          accountType: '',
          loginType: '',
          financeAccounts: '',
          financeName: ''
        }
      } else if ((this.title = '编辑收款账户')) {
        this.formData = this.accountInfo
      }
    }
  },
  mounted() {},
  methods: {
    close() {
      this.$emit('closeAddAccount')
    },
    handleClose() {
      this.addAccount = false
      let spBusInfo = session.getUserInfo()
      this.formData = {
        spBusId: spBusInfo.id,
        accountType: '',
        loginType: '',
        financeAccounts: '',
        financeName: ''
      }
    },
    // 关闭弹框
    openClose() {
      this.$emit('closeAddAccount')
    },
    userConfirmAddAccountInfo() {
      if (this.title == '添加收款账户') {
        this.confirmAddAccountInfo(this.formData)
      } else if ((this.title = '编辑收款账户')) {
        this.editAccountInfo(this.formData)
      }
      let spBusInfo = session.getUserInfo()
      this.formData = {
        spBusId: spBusInfo.id,
        accountType: '',
        loginType: '',
        financeAccounts: '',
        financeName: ''
      }
    }
  }
}
</script>
<style lang="less" scoped>
</style>