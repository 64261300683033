import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import Login from '../views/login/login.vue'
import Register from '../views/login/register.vue'
import Index from '../views/home/index.vue'
import ReceivingOrders from '../views/receivingOrders/index.vue'
import Orders from '../views/orders/index.vue'
import Finance from '../views/finance/index.vue'
import Shop from '../views/shop/index.vue'
import Department from '../views/shop/department.vue'
import Support from '../views/support/index.vue'
import SysToSpBusPay from '../views/finance/sysToSpBusPay.vue'

Vue.use(VueRouter)

const routes = [
  { path: '/', redirect: '/login' }, // 默认
  // 登录
  { path: '/login', component: Login },
  // 注册
  { path: '/register', component: Register },
  // 首页
  {
    path: '/home',
    name: 'Home',
    component: Home,
    children: [
      { path: '/', redirect: '/receivingOrders' }, // 默认
      // { path: '/index', component: Index }, // 首页
      { path: '/receivingOrders', component: ReceivingOrders }, // 接单
      { path: '/orders', component: Orders }, // 订单
      { path: '/finance', component: Finance }, // 财务
      { path: '/shop', component: Shop }, // 店铺
      { path: '/department', component: Department }, // 成员管理
    ]
  },
  { path: '/sysToSpBusPay', component: SysToSpBusPay }, // 商家提现
  // 帮助中心
  { path: '/support', component: Support },
]

const router = new VueRouter({
  routes
})

export default router
