<template>
  <div class="pageBox">
    <div>首页</div>
  </div>
</template>
<script>
export default {
  components: {},
  data() {
    return {}
  },
  created() {},
  mounted() {},
  beforeDestroy() {},
  methods: {}
}
</script>
<style lang="less" scoped>
.pageBox {
  width: 100%;
  height: 100%;
  color: #333;
  font-size: 14px;
  letter-spacing: 1px;
}
</style>
