<template>
  <el-container>
    <el-dialog title="受理退款/售后" :visible.sync="confirmOrder" width="40%" @close="close" append-to-body>
      <el-form :model="ruleForm" label-width="100px" class="demo-ruleForm">
        <el-form-item label="审核结果">
          <el-select v-model="ruleForm.spBusResult" placeholder="请选择审核结果">
            <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value"> </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="备注" prop="desc">
          <el-input type="textarea" v-model="ruleForm.spBusResultNotes"></el-input>
        </el-form-item>
        <el-form-item>
          <el-button @click="closeConfirmOrderOrder">取 消</el-button>
          <el-button type="primary" @click="openSave">确 认</el-button>
        </el-form-item>
      </el-form>
    </el-dialog>
  </el-container>
</template>
<script>
// 从session中获取token信息
import session from '@/utils/session.js'
export default {
  props: ['confirmOrder', 'refundInfo', 'getSpBusRefund'],
  data() {
    return {
      refundInfo: {},
      ruleForm: {
        spBusResult: null,
        spBusResultNotes: null
      },
      options: [
        {
          value: 1,
          label: '同意'
        },
        {
          value: 0,
          label: '拒绝'
        }
      ]
    }
  },
  watch: {
    refundInfo(n) {
      this.refundInfo = n
    }
  },
  mounted() {},
  methods: {
    // 关闭弹框
    closeConfirmOrderOrder() {
      this.confirmOrder = false
    },
    close() {
      this.$emit('closeConfirmOrderOrder')
    },
    openSave() {
      this.getSpBusRefund(this.ruleForm)
    }
  }
}
</script>
<style lang="less" scoped>
.el-select {
  width: 100%;
}
.input_item {
  padding: 8px 0;
  .item_vlaue {
    padding-bottom: 8px;
  }
  .select {
    width: 100%;
  }
}
.el-cascader {
  width: 100%;
}
</style>
