<template>
  <el-container>
    <el-dialog title="编辑账号信息" :visible.sync="editAccountInfo" width="40%" @close="close" append-to-body>
      <div v-if="passwordSatus == false">
        <el-form label-width="120px">
          <el-form-item label="身份验证">
            <el-input v-model="password" type="password" placeholder="请输入账号密码"></el-input>
          </el-form-item>
        </el-form>
        <div slot="footer" class="dialog-footer">
          <el-button @click="closeEditAccountInfo">取 消</el-button>
          <el-button type="primary" @click="openProv()">验证</el-button>
        </div>
      </div>
      <div v-if="passwordSatus == true">
        <el-form label-width="120px" :model="formData">
          <el-form-item label="负责人姓名">
            <el-input v-model="formData.contactPerson" placeholder="请输入"></el-input>
          </el-form-item>
          <el-form-item label="负责人电话">
            <el-input :disabled="true" v-model="formData.contactPhone" placeholder="请输入"></el-input>
          </el-form-item>
          <el-form-item label="账号密码">
            <el-input placeholder="请输入" type="password" v-model="formData.accountPassword"></el-input>
          </el-form-item>
        </el-form>
        <div slot="footer" class="dialog-footer">
          <el-button @click="closeEditAccountInfo">取 消</el-button>
          <el-button type="primary" @click="openSave()">保存</el-button>
        </div>
      </div>
    </el-dialog>
  </el-container>
</template>
<script>
export default {
  props: ['editAccountInfo', 'accountInfo', 'editAccountFormData'],
  data() {
    return {
      passwordSatus: false,
      password: '',
      formData: {
        contactPerson: '',
        contactPhone: '',
        accountPassword: ''
      }
    }
  },
  watch: {
    accountInfo(n, o) {
      this.formData = n
    }
  },
  mounted() {},
  methods: {
    // 关闭弹框
    closeEditAccountInfo() {
      this.editAccountInfo = false
    },
    close() {
      this.$emit('closeEditAccountInfo')
    },
    openSave() {
      this.editAccountFormData(this.formData)
      this.editAccountInfo = false
      this.passwordSatus = false
      this.password = ''
    },
    // 密码验证
    openProv() {
      if (this.password == this.formData.accountPassword) {
        this.passwordSatus = true
      } else {
        this.$message.error('密码输入有误，验证失败！')
        this.password = ''
      }
    }
  }
}
</script>
<style lang="less" scoped>
.el-select {
  width: 100%;
}
.input_item {
  padding: 8px 0;
  .item_vlaue {
    padding-bottom: 8px;
  }
  .select {
    width: 100%;
  }
}
.el-cascader {
  width: 100%;
}
.depositBox {
  margin-top: 8px;
  .deposit_content {
    padding: 16px;
    background: #09bebe;
    border-radius: 4px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: #fff;
    .d_l_tit {
      font-size: 18px;
      font-weight: bold;
    }
    .d_l_dec {
      font-size: 14px;
    }
  }
  p {
    font-size: 12px;
  }
}
.dialog-footer {
  display: flex;
  justify-content: flex-end;
}
</style>
