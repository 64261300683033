<template>
  <el-container>
    <el-dialog title="编辑服务信息" :visible.sync="editServiceInfo" width="60%" @close="close" append-to-body>
      <el-form label-width="80px" :model="formData">
        <el-form-item label="服务项目">
          <el-input v-model="formData.serviceItems" placeholder="请输入"></el-input>
        </el-form-item>
        <el-form-item label="服务地区">
          <el-input v-model="formData.serviceArea" placeholder="请输入"></el-input>
        </el-form-item>
        <el-form-item label="服务时间">
          <el-input
            type="textarea"
            placeholder="例如：每周一至周日 08:00 - 18:00"
            :rows="2"
            v-model="formData.serviceTime"
          ></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="closeEditServiceInfo">取 消</el-button>
        <el-button type="primary" @click="openSave()">保存</el-button>
      </span>
    </el-dialog>
  </el-container>
</template>
<script>
// 自定义拖拽
import elDragDialog from '@/directive/el-dragDialog'
export default {
  props: ['editServiceInfo', 'serviceInfo', 'editServiceFormData'],
  directives: {
    elDragDialog
  },
  data() {
    return {
      formData: {
        serviceItems: '',
        serviceArea: '',
        serviceTime: ''
      }
    }
  },
  watch: {
    serviceInfo(n, o) {
      this.formData = n
    }
  },
  mounted() {},
  methods: {
    // 关闭弹框
    closeEditServiceInfo() {
      this.editServiceInfo = false
    },
    close() {
      this.$emit('closeEditServiceInfo')
    },
    openSave() {
      this.editServiceFormData(this.formData)
      this.editServiceInfo = false
    }
  }
}
</script>
<style lang="less" scoped>
.el-select {
  width: 100%;
}
.input_item {
  padding: 8px 0;
  .item_vlaue {
    padding-bottom: 8px;
  }
  .select {
    width: 100%;
  }
}
.el-cascader {
  width: 100%;
}
.depositBox {
  margin-top: 8px;
  .deposit_content {
    padding: 16px;
    background: #09bebe;
    border-radius: 4px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: #fff;
    .d_l_tit {
      font-size: 18px;
      font-weight: bold;
    }
    .d_l_dec {
      font-size: 14px;
    }
  }
  p {
    font-size: 12px;
  }
}
</style>
