import request from '@/utils/request'

// 登录方法
export function login(data) {
    return request({
        url: '/sp/busInfo/login',
        method: 'post',
        data: data
    })
}

// 解析token
export function tokenParse(data) {
    return request({
        url: '/sp/busInfo/profile',
        method: 'post',
        data: data
    })
}

// 注册
export function register(data) {
    return request({
        url: '/sp/busInfo/register',
        method: 'post',
        data: data
    })
}
// 发送注册验证码
export function validateCode(mobile) {
    return request({
        url: '/sp/busInfo/validateCode/' + mobile,
        method: 'get',
    })
}
// 发送普通验证码
export function ordinaryValidateCode(mobile) {
    return request({
        url: '/sp/busDepartment/validateCode/' + mobile,
        method: 'get',
    })
}