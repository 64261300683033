<template>
  <el-dialog
    title="身份验证"
    :visible.sync="obtainCode"
    @close="close"
    width="30%"
    :before-close="handleClose"
    :close-on-click-modal="false"
  >
    <div class="formData">
      <el-form :label-position="labelPosition" label-width="80px" :model="formData" :rules="rules" ref="formData">
        <el-form-item label="财务手机">
          <span>{{ mobileStr(formData.mobile) }}</span>
        </el-form-item>
        <el-form-item label="验证码" prop="code">
          <el-input v-model="formData.code" placeholder="请输入验证码"></el-input>
          <div class="itemLabel" style="color: #09bebe; cursor: pointer" @click="openCode">获取验证码</div>
          <span v-if="obtainCodeStatus == true" style="font-size: 12px; color: #09bebe">验证码正确</span>
        </el-form-item>
      </el-form>
    </div>
    <span slot="footer" class="dialog-footer">
      <el-button @click="obtainCode = false">取 消</el-button>
      <el-button type="primary" @click="userConfirmObtainInfo()">确 定</el-button>
    </span>
  </el-dialog>
</template>
<script>
// 从session中获取token信息
import session from '@/utils/session.js'
import { ordinaryValidateCode } from '@/api/login.js'
export default {
  props: ['obtainCode', 'confirmObtainInfo'],
  data() {
    var validatePass = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('请输入验证码'))
        this.obtainCodeStatus = false
      } else if (this.formData.code !== this.sysCode) {
        callback(new Error('您输入的验证码错误！'))
        this.obtainCodeStatus = false
      } else {
        callback()
        this.obtainCodeStatus = true
      }
    }
    return {
      formData: {
        mobile: '',
        code: ''
      },
      sysCode: null,
      obtainCode: false,
      obtainCodeShow: this.obtainCode,
      obtainCodeStatus: false,
      labelPosition: 'right',
      rules: {
        code: [{ validator: validatePass, trigger: 'blur' }]
      }
    }
  },
  mounted() {
    this.getSpBusInfo()
  },
  methods: {
    // 获取商家信息
    getSpBusInfo() {
      let spBusInfo = session.getUserInfo()
      this.formData.mobile = spBusInfo.financePhone
    },
    close() {
      this.$emit('closeObtainCode')
    },
    handleClose() {
      this.obtainCode = false
    },
    userConfirmObtainInfo() {
      if (this.obtainCodeStatus) {
        this.confirmObtainInfo()
      } else {
        this.$message.error('请输入短信验证码！')
      }
    },
    async openCode() {
      if (!!this.formData.mobile) {
        let res = await ordinaryValidateCode(this.formData.mobile)
        if (res.success) {
          this.$message({
            message: '验证码已发送',
            type: 'success'
          })
          this.sysCode = res.data
          console.log('sysCode', this.sysCode)
        } else {
          this.$message.error(res.message)
        }
      }
    }
  }
}
</script>
<style lang="less" scoped>
.formData {
  margin: 0 auto;
  padding: 0 24px;
  .itemLabel {
    position: absolute;
    top: 0;
    right: 24px;
  }
}
</style>