import request from '@/utils/request'

// 接单管理 - 根据服务商地区显示接单列表，默认根据服务商cityId显示订单
export function pageSpCityIdAll() {
    return request({
        url: '/sp/userOrders/pageSpCityIdAll',
        method: 'get',
    })
}
// 接单管理 - 根据id查询
export function findByGoodsId(id) {
    return request({
        url: '/sp/serviceGoods/' + id,
        method: 'get',
    })
}
// 接单管理 - 接单
export function spReceivingOrders(id, data) {
    return request({
        url: '/sp/userOrders/spReceivingOrders/' + id,
        method: 'PUT',
        data: data
    })
}
// 订单管理 - 根据服务商id查询订单
export function pageSpBusId(data) {
    return request({
        url: '/sp/userOrders/pageSpBusId',
        method: 'get',
        params: data
    })
}
// 订单管理 - 根据服务商id查询所有待服务订单，用于App端接口大厅数据显示,订单状态 0 待支付 1 待确认 2 在线预约 3 待服务 4 已完成 5 已退款  7取消订单
export function findBySpBusIdOrdersStatus(orderStatus) {
    return request({
        url: '/sp/userOrders/findBySpBusIdOrdersStatus/' + orderStatus,
        method: 'get',
    })
}
// 订单管理 - 根据退款/售后id查询详情
export function getUserOrdersRefundById(id) {
    return request({
        url: '/sp/userOrdersRefund/' + id,
        method: 'get',
    })
}
// 订单管理 - 根据spBusId 查询退款售后列表
export function getUserOrdersRefundBySpBusIdList(id) {
    return request({
        url: '/sp/userOrdersRefund/findBySpBusIdList',
        method: 'get',
    })
}
// 订单管理 - 根据订单id 查询订单详情
export function getOrderDetailsById(id) {
    return request({
        url: '/sp/userOrders/' + id,
        method: 'get',
    })
}
// 订单管理 - 根据退款/售后ID 审核
export function getSpBusRefund(id, data) {
    return request({
        url: '/sp/userOrdersRefund/spBusUpdate/' + id,
        method: 'put',
        data: data
    })
}